import React from "react";
import ReactDOM from "react-dom/client";
import "./ui/css/index.css";
import "./ui/css/public.scss";
import { Provider } from "react-redux";
import { Store } from "./app/Store";
import "antd/dist/antd.css";
// Import Swiper styles
import "swiper/css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { FirstPage } from "./ui/pages/First";
import { HomePage } from "@/ui/pages/Home/index";
import { Chat } from "@/ui/pages/chat/chat";
import { ChatHistory } from "@/ui/pages/chat/chatHistory";
import { CreateChat } from "@/ui/pages/chat/createChat";
import { Login } from "@/ui/pages/login/index";
import { My } from "@/ui/pages/my/index";
import { MyNft } from "@/ui/pages/my/MyNft";
import { EditMyMsg } from "@/ui/pages/my/editMyMsg";
import { PurchaseRecord } from "@/ui/pages/my/PurchaseRecord";
import { TransactionRecord } from "@/ui/pages/my/TransactionRecord";
import { TransactionRecordMsc } from "@/ui/pages/my/TransactionRecordMsc";
import { NftHome } from "@/ui/pages/nfts/NftHome";
import { Nfts } from "@/ui/pages/nfts/Nfts";
import { PayToSupport } from "@/ui/pages/nfts/PayToSupport";
import { BlindBox } from "@/ui/pages/nfts/BlindBox";
import { Transfer } from "@/ui/pages/my/Transfer";
import { PlayLoading } from "@/ui/components/PlayLoading";
import { PlayOk } from "@/ui/components/PlayOk";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <Provider store={Store}>
      <Routes>
        <Route path="/" element={<FirstPage />}>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
          <Route path="/ChatHistory" element={<ChatHistory />}></Route>
          <Route path="/CreateChat" element={<CreateChat />}></Route>
          <Route path="/my" element={<My />}></Route>
          <Route path="/myNft" element={<MyNft />}></Route>
          <Route path="/edit" element={<EditMyMsg />}></Route>
          <Route path="/purchaseRecord" element={<PurchaseRecord />}></Route>
          <Route
            path="/transactionRecord"
            element={<TransactionRecord />}
          ></Route>
          <Route
            path="/transactionRecordMsc"
            element={<TransactionRecordMsc />}
          ></Route>
          <Route path="/NftHome" element={<NftHome />}></Route>
          <Route path="/nfts" element={<Nfts />}></Route>
          <Route path="/PayToSupport" element={<PayToSupport />}></Route>
          <Route path="/BlindBox" element={<BlindBox />}></Route>
          <Route path="/Transfer" element={<Transfer />}></Route>
          <Route path="/PlayLoading" element={<PlayLoading />}></Route>
          <Route path="/PlayOk" element={<PlayOk />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </Provider>
  </Router>
);
