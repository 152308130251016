/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @typescript-eslint/no-unused-vars */
import './PayToSupport.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from 'react-router-dom'
import { Space, Swiper, Collapse, Radio, Popup, SpinLoading, Toast, Mask,Button } from 'antd-mobile'
import { CloseOutline, DownOutline, RightOutline } from 'antd-mobile-icons'
import { Footer } from '@/ui/components/Footer'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { State, useAppDispatch, useAppSelector } from '@/app/Store'
import {
  ConnectSelectors,
  ConnectTask,
  disconnect,
  SwitchTask, 
} from "@/slices/ConnectSlice";
import { ChainId, ethereum, web3 } from "@/app/Config";
import { getLumiProxy, getErc20Contract } from '@/app/Contract';
import lumiProxy_abi from "@/contracts/lumiProxy_abi.json";

import { CMaks } from '@/ui/components/CMaks'
import { Modal,message } from 'antd';


Paypal.defaultProps = {
  visible: false,
  id: '', //id
  onClose: () => { },
}
export function Paypal(props: any) {

  const [CMaksVisible, setCMaksVisible] = useState<boolean>(false)

  const dispatch = useAppDispatch();
  const { member } = useAppSelector((state: State) => state.user)
  const navigate = useNavigate()
  const skip = (url: To) => {
    navigate(url)
  }
  const { address, chainId } = useAppSelector(ConnectSelectors.userData);
  const isConnected = useAppSelector(ConnectSelectors.isConnected);
  const isLoading = useAppSelector(ConnectSelectors.isLoading);

  async function approveErc20() {
    setLoading_but(true);
    const contract = await getErc20Contract();
    contract.methods.approve(lumiProxy_abi.address, web3.utils.toWei(payData.orderAmount + "", "Mwei")).send({
      from: address
    }).on('error', (error: any) => {
      if(Common.isMobile){
        Toast.show({
          icon: 'fail',
          content: "cancel pay",
        })
      }else{
        message.error("cancel pay")
      }
      setLoading_but(false);
    }).on('transactionHash', (txHash: any) => {
      console.warn("transactionHash", txHash)
    }).on('receipt', (receipt: any) => {
      setLoading_but(false);
      if(Common.isMobile){
        Toast.show({
          icon: 'success',
          content: 'approve successful',
        })
        getErc20Approve();
      }else{
        getErc20Approve();
        message.success("approve successful")
      }
    })
  }

  async function getErc20Approve() {
    if (address) {
      const contract = await getErc20Contract();
      payData.allowance =web3.utils.fromWei(await contract.methods.allowance(address, lumiProxy_abi.address).call()+ "", "Mwei") ;
      setPayData({ ...payData })
    }

  }


  function connect() {
    !isConnected && !isLoading && dispatch(ConnectTask());
  }
  function switch_() {
    dispatch(SwitchTask())
  }

  const text = isLoading ? "CONNECTING..." :
    isConnected ? chainId === ChainId ?
      "Pay" : "SWITCH" : "CONNECT";

  function textTre(text: any) {
    return "SWITCH,CONNECT".indexOf(text) == -1;
  }


  const onClick = isLoading ? undefined :
    isConnected ? chainId === ChainId ?
      undefined : switch_ : connect;



  async function pay() {
    const contract = await getLumiProxy();
    setLoading_but(true);
    contract.methods.deposit(payData.orderNo, web3.utils.toWei(payData.orderAmount + "", "Mwei"), address).send({
      from: address
    }).on('error', (error: any) => {
        if(Common.isMobile){
          Toast.show({
            icon: 'fail',
            content: "cancel pay",
          })
        }else{
          message.error("cancel pay")
        }
        setLoading_but(false);
    }).on('transactionHash', (txHash: any) => {
      console.warn("transactionHash", txHash);
    }).on('receipt', (receipt: any) => {
      setLoading_but(false);
      if(Common.isMobile){
        Toast.show({
          icon: 'success',
          content: 'Payment successful',
        })
        props.onClose();
      }else{
        props.onClose();
        message.success("Payment successful");
        createOrder();
      }
    })
  }



  const [payData, setPayData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [loading_but, setLoading_but] = useState<boolean>(false)
  
  function createOrder(){
      httpGet('/memberrecharge/createOrderNO/' + props.id).then(async (res: any) => {
        const { data } = res
        setLoading(false)
        if (data.code === 0) {
          if (address) {
            const contract = await getErc20Contract();
            data.data.allowance =web3.utils.fromWei(await contract.methods.allowance(address, lumiProxy_abi.address).call() + "", "Mwei") ;
          }
          setPayData((payData: any) => ({ ...payData, ...data.data }))
        }
      })
  }


  useEffect(() => {
    if (props.visible && props.id) {
      setLoading(true)
      createOrder();
    }
  }, [props.visible, props.id])


  useEffect(() => {
    if (isConnected && chainId && address) {
      getErc20Approve();
    }
  }, [isConnected, chainId]);


  // @ts-ignore
  const Web = <div id="Paypal" mode="web">
    <Modal
      width={"25vw"}
      visible={props.visible}
      closable={false}
      footer={null}
      bodyStyle={{ minHeight: '50vh', padding: '0'}}>
      <div
        className="flexsb pt-20 px-14"
        style={{ borderBottom: '1px solid #F3F3F3' }}>
        <div className="f-26 f-w-7">Pay by Crypto</div>
        <CloseOutline onClick={() => props.onClose()} className="f-20" />
      </div>
      {loading  ? (
        <div className='flexcc w-100 h-80'><SpinLoading style={{ '--size': '48px' }} /></div>
      ) : (
        <div className="px-14 py-44">
          {payData?.rechargeConfigEntity?.type == 1 ? (
            <div>
              <div className="f-16 f-w-5">Details</div>
              <div className="flexsb my-22" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">NFT</div>
                <div className="f-16 f-w-4 flexc">
                  {payData.commodityAmount} USDbC
                </div>
              </div>
              <div className="flexsb" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">gas fee</div>
                <div className="f-16 f-w-4 flexc">
                  {payData.feeAmount} USDbC
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="f-16 f-w-5">Details</div>
              <div className="flexsb mt-10" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">
                  {payData.rechargeConfigEntity?.buyNum} times
                </div>
                <div className="f-16 f-w-4 flexc">
                  {payData.commodityAmount} USDbC
                </div>
              </div>
            </div>
          )}

          <div
            className="w-100 h-r-2 mt-9 mb-18"
            style={{ background: '#E0E0E0' }}></div>
          <div className="flexsb">
            <div className="f-16 f-w-5">Subtotal</div>
            <div className="f-16 f-w-4" style={{ color: '#666' }}>
              <span className="f-20 f-w-7 text-black">
                {payData.orderAmount}
              </span>{' '}
              USDbC
            </div>
          </div>
          {
            textTre(text) ?
              (payData.allowance >= payData.orderAmount ? <Button  loading={loading_but}  loadingText={'loading...'}   onClick={() => pay()} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou" >
                {text}
              </Button> : <Button  loading={loading_but}   loadingText={'loading...'}  onClick={() => { approveErc20() }} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou"  >Approval</Button>)
              :
              <Button onClick={onClick}  loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou" >{text}</Button>
          }
        </div>
      )}
    </Modal>

  </div>
  // @ts-ignore
  const Mobile = (<div id="Paypal" mode="mobile">
    <Popup
      closeOnMaskClick={false}
      visible={props.visible}
      bodyStyle={{ height: '90vh', borderRadius: '20px 20px 0 0' }}>
      <div
        className="flexsb pt-20 px-14"
        style={{ borderBottom: '1px solid #F3F3F3' }}>
        <div className="f-26 f-w-7">Paypal</div>
        <CloseOutline onClick={() => props.onClose()} className="f-20" />
      </div>
      {loading && payData.orderAmount>0 ? (
        <div className='flexcc w-100 h-80'><SpinLoading style={{ '--size': '48px' }} /></div>
      ) : (
        <div className="px-14 py-44">
          {payData?.rechargeConfigEntity?.type == 1 ? (
            <div>
              <div className="f-16 f-w-5">Details</div>
              <div className="flexsb my-22" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">NFT</div>
                <div className="f-16 f-w-4 flexc">
                  {payData.commodityAmount} USDbC
                </div>
              </div>
              <div className="flexsb" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">gas fee</div>
                <div className="f-16 f-w-4 flexc">
                  {payData.feeAmount} USDbC
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="f-16 f-w-5">Details</div>
              <div className="flexsb mt-10" style={{ color: '#666' }}>
                <div className="f-16 f-w-4">
                  {payData.rechargeConfigEntity?.buyNum} times
                </div>
                <div className="f-16 f-w-4 flexc">
                  {payData.commodityAmount} USDbC
                </div>
              </div>
            </div>
          )}

          <div
            className="w-100 h-r-2 mt-9 mb-18"
            style={{ background: '#E0E0E0' }}></div>
          <div className="flexsb">
            <div className="f-16 f-w-5">Subtotal</div>
            <div className="f-16 f-w-4" style={{ color: '#666' }}>
              <span className="f-20 f-w-7 text-black">
                {payData.orderAmount}
              </span>{' '}
              USDbC
            </div>
          </div>
          {
            textTre(text) ?
              (payData.allowance >= payData.orderAmount ? <Button loading={loading_but} loadingText={'loading...'}   onClick={() => pay()} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou" >
                {text}
              </Button> : <Button loading={loading_but}  loadingText={'loading...'}   onClick={() => { approveErc20() }} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou shou"  >Approval</Button>)
              :
              <Button  loading={loading_but}  loadingText={'loading...'}  onClick={onClick} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 my-43 bg-xitong text-white shou" >{text}</Button>
          }
        </div>
      )}
      {/* 全局弹窗 */}
      <CMaks visible={CMaksVisible}></CMaks>
    </Popup>
  </div>
  )
  return Common.isMobile ? Mobile : Web
}
