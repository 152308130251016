/* eslint-disable @typescript-eslint/no-unused-vars */
import './TransactionRecordMsc.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Tabs } from 'antd-mobile'
import { useLocation } from 'react-router-dom';
export function TransactionRecordMsc() {
  const location = useLocation();
  const data = location.state as any;
  
  // @ts-ignore
  const Web = <div id="TransactionRecordMsc" mode="web"></div>
  // @ts-ignore
  const Mobile = (<div id="TransactionRecordMsc" mode="mobile">
      <Navigation></Navigation>
      <div className="transactionRecordMscBox px-14 py-34">
        <div className="f-26 f-w-7 mb-30">Transaction Record</div>
        <div className='w-100 py-20' style={{borderBottom:'1px solid #E0E0E0'}}>
            <div className='d-flex justify-content-between mb-30'>
              <div className='f-12 f-w-4' style={{color:'#666666'}}>Form</div>
              <div className='f-12 f-w-4 w-70  text-right' style={{color:'#242424',wordWrap:"break-word" }}>{data.fromAddres}</div>
            </div>
            <div className='d-flex justify-content-between mb-30'>
              <div className='f-12 f-w-4' style={{color:'#666666'}}>To</div>
              <div className='f-12 f-w-4 w-70  text-right' style={{color:'#242424',wordWrap:"break-word" }}>{data.toAddres}</div>
            </div>
            <div className='d-flex justify-content-between mb-30'>
              <div className='f-12 f-w-4' style={{color:'#666666'}}>TRX</div>
              <div className='f-12 f-w-4 w-70  text-right' style={{color:'#242424',wordWrap:"break-word" }}>{data.tx}</div>
            </div>
            <div className='flexsb'>
              <div className='f-12 f-w-4' style={{color:'#666666'}}>Time</div>
              <div className='f-12 f-w-4' style={{color:'#242424'}}>{data.date}</div>
            </div>
        </div>
        <div onClick={()=>window.history.back()} className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 mt-37" style={{border: "1px solid #343439"}}>
          Go Back
        </div>
      </div>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
