import React, { useState, useEffect } from 'react';

function TextAnimation(props:any) {
  const [text, setText] = useState('');
  useEffect(() => {
    if(props.text){
      let i = 0;
      const interval = setInterval(() => {
        setText(props.text.substring(0, i));
        i++;
        if (i > props.text.length) {
          clearInterval(interval);
          props.onDisabled()
        }
      }, 20);
      return () => clearInterval(interval);
    }

  }, [props.text]);
  
  return <span>{text}</span>;
}

export default TextAnimation;
