/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './index.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { request } from 'http'
import { Navigation, } from '@/ui/components/Navigation'
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { init, clear,memberAsync } from '@/slices/user'
import { To, useNavigate } from "react-router-dom";
import { Ellipsis, Toast } from 'antd-mobile'
import { httpGet, httpPost, host_url } from '@/utils/httpUtils';
import copy from 'copy-to-clipboard';
export function My() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  const dispatch = useAppDispatch()
  const { member } = useAppSelector((state: State) => state.user)

  const logout = () => {
    dispatch(clear())
    localStorage.removeItem("token")
    skip('/')
  }

  
  useEffect(() => {
    dispatch(memberAsync())
  }, []);


  // 复制内容
  const copyCot = (cot: string) => {
    copy(cot);
    Toast.show({
      icon: 'success',
      content: 'Replicating Success',
    })
  }


  function tw() {
    let metaArr = [
      'twitter:title', "http://test.godwithme.ai/",
      'twitter:card', 'summary_large_image',
      'twitter:url', "http://test.godwithme.ai/",
    ]
    let metaParams = encodeURIComponent(encodeURIComponent(metaArr.toString()))
    httpGet('/member/shareRewards ').then((e: any) => {
      if (e.data.code == 0) {
        window.open(`https://twitter.com/share?text=Welcome to join Lumi &url=${host_url}/api/app/share/new?meta=${metaParams}`)
      } else {
        Toast.show({
          icon: 'fail',
          content: 'The number of shares has been used up', //信息
        })
      }
    })
  }



  // @ts-ignore
  const Web = <div id="my text-black" mode="web">
    <Navigation></Navigation>

    <div className='flexcc w-100  pt-140' >
        <div className="w-r-1280" >
    <div className="px-436">
      <div>
        <div className='flexc h-r-38'>
          <img
            className="w-r-32 h-r-32 br-32"
            src={member.headImg ? member.headImg : require('@/assets/mobile/my/h.png')}
            alt=""
          />
          <div className='flexc'>
            <div className="f-32 f-w-9 px-10">{member.userName}</div>
            <img onClick={() => skip('/edit')} className="w-r-16 h-r-16 shou" src={require('@/assets/mobile/my/edit.png')} />
          </div>
        </div>
        <div className='f-12 f-w-4 text-a8 w-100 mt-8'>
          <div className='flexsb'>
            <div>mailto:{member.email}</div>
            {/* <img className="w-r-10 h-r-13" src={require('@/assets/mobile/my/c.png')} /> */}
          </div>
          <div onClick={() => copyCot(member.walletAddress)}>
            <div className='walletAddress shou' >BASE:
                <span onClick={() => copyCot(member.walletAddress)} >
                  {member.walletAddress}
                </span>
                
                 <img
                 onClick={()=>{
                   window.open(`https://basescan.org/address/${member.walletAddress}`);
                 }}
              className="w-r-18 h-r-18 br-32 ml-20"
              src={require('@/assets/mobile/my/favicon3.ico')}
              alt=""
            />
            </div>
          </div>
        </div>
      </div>


      <div className='h-r-76 pl-6 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
        <img className="w-r-16 h-r-15 mr-18" src={require('@/assets/mobile/my/Timesleft.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' >
          <div className='f-14 f-w-4 my_item'>Times left</div>
          <div className='f-14 f-w-4'>{ member && member.nftList &&  member.nftList.length > 0 ?  "unlimited":member.questionNum + member.dailyQuestionNum} times</div>
        </div>
      </div>
      <div className='h-r-76 pl-6 flexsb shou' onClick={() => skip('/myNft')} style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/n.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' >
          <div className='f-14 f-w-4 my_item'>NFTs</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-76 pl-6 flexsb shou' onClick={() => skip('/purchaseRecord')} style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/pu.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' >
          <div className='f-14 f-w-4 my_item'>Purchase record</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-76 pl-6 flexsb shou' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/share.png')} />
        <div onClick={tw} className='flex-1 h-100 pr-15 flexsb' >
          <div className='f-14 f-w-4 my_item'>Share to get times</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-8' style={{ background: '#EDEDED', border: "1px solid #E3E3E3" }}></div>
      <div className='h-r-76 pl-6 flexsb shou' onClick={logout} style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
        <img className="w-r-16 h-r-20 mr-18" src={require('@/assets/mobile/my/out.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' >
          <div className='f-14 f-w-4 my_item'>Logout</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
    </div>
    </div>
    </div>

  </div>
  // @ts-ignore
  const Mobile = (<div id="my" mode="mobile">
    <Navigation></Navigation>
    <div className="myBox">
      <div className="myBox__header mt-26 px-16 d-flex">
        <img
          className="w-r-32 h-r-32 br-32"
          src={member.headImg ? member.headImg : require('@/assets/mobile/my/h.png')}
          alt=""
        />
        <div className='w-100'>
          <div className='myBox__name flexc'>
            <div className=" f-26 f-w-7 px-10">{member.userName}</div>
            <img onClick={() => skip('/edit')} className="w-r-16 h-r-16" src={require('@/assets/mobile/my/edit.png')} />
          </div>
          <div className='my_msg f-12 f-w-4 text-a8 w-100'>
            <div className='flexsb'>
              <div>mailto:{member.email}</div>
              <img className="w-r-10 h-r-13" src={require('@/assets/mobile/my/c.png')} />
            </div>
            <div onClick={() => copyCot(member.walletAddress)}>
              <div className='walletAddress'   onClick={()=>{
                   window.open(`https://basescan.org/address/${member.walletAddress}`);
                 }} >BASE:{member.walletAddress}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='h-r-76 pl-20 flexsb'>
        <img className="w-r-16 h-r-15 mr-18" src={require('@/assets/mobile/my/Timesleft.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
          <div className='f-14 f-w-4'>Times left</div>
          <div className='f-14 f-w-4'>{ member && member.nftList &&  member.nftList.length > 0 ?  "unlimited":member.questionNum + member.dailyQuestionNum} times</div>
        </div>
      </div>
      <div className='h-r-76 pl-20 flexsb' onClick={() => skip('/myNft')}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/n.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
          <div className='f-14 f-w-4'>NFTs</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-76 pl-20 flexsb' onClick={() => skip('/purchaseRecord')}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/pu.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
          <div className='f-14 f-w-4'>Purchase record</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-76 pl-20 flexsb'>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/my/share.png')} />
        <div onClick={tw} className='flex-1 h-100 pr-15 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
          <div className='f-14 f-w-4'>Share to get times</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
      <div className='h-r-8' style={{ background: '#EDEDED', border: "1px solid #E3E3E3" }}></div>
      <div className='h-r-76 pl-20 flexsb' onClick={logout}>
        <img className="w-r-16 h-r-20 mr-18" src={require('@/assets/mobile/my/out.png')} />
        <div className='flex-1 h-100 pr-15 flexsb' style={{ borderBottom: '1px solid #E0E0E0', color: '#242424' }}>
          <div className='f-14 f-w-4'>Logout</div>
          <img className="w-r-10 h-r-15 mr-18" src={require('@/assets/mobile/my/right.png')} />
        </div>
      </div>
    </div>
  </div>
  )
  return Common.isMobile ? Mobile : Web
}
