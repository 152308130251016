/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./MyNft.scss";
import { Common } from "@/app/Common";
import { Key, useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Tabs,InfiniteScroll,DotLoading,Input,Empty } from 'antd-mobile'
import { SearchOutline,RightOutline } from 'antd-mobile-icons'
import { To, useNavigate } from "react-router-dom";
import { httpGet, httpPost } from '@/utils/httpUtils'

export function MyNft() {

  const navigate = useNavigate();
	const skip = (url: To) => {
		navigate(url)
	}

  const [remainingHeight, setRemainingHeight] = useState(0);
  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const headerHeight = document.getElementById('navigation')?.getBoundingClientRect().height || 0;
      const footerHeight = document.getElementById('nftsBox')?.getBoundingClientRect().height || 0;
      const remainingHeight = windowHeight - headerHeight - footerHeight;
      setRemainingHeight(remainingHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    getPCMyNft()
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getPCMyNft = async () => {
    await httpPost("/nftDetail/getMyNft",{page:1,limit:500,tokenId:message}).then((res:any) => {
      const {data}=res;
      if(data.code === 0) {
        setMyNftListPc([...data.data.list])
      }
    })
  }



  //searchData
  const [message,setMessage] = useState("")
  const [hasMore, setHasMore] = useState(true)
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>--- Loading completed ---</span>
        )}
      </>
    )
  }
  const [MyNftList, setMyNftList] = useState<any>([])
  const [MyNftListPc, setMyNftListPc] = useState<any>([])
  const [page, setPage] = useState(1)
  const loadMore = async () => {
    await setPage((page) => {
      page++
      return page
    })
    console.log(page);
    await getMyNft()
  }
  const getMyNft = async (newPage?:number) => {
    await httpPost("/nftDetail/getMyNft",{page:newPage?newPage:page,limit:10,tokenId:message}).then((res:any) => {
      const {data}=res;
      if(newPage) setPage(1);
      if(data.code === 0) {
        setMyNftList((val: any) => (newPage ==1 || page==1 )?[...data.data.list] : [...val, ...data.data.list])
        data.data.list.totalCount === MyNftList.length ? setHasMore(true):setHasMore(false) 
        console.log(data.data.list.totalCount === MyNftList.length );
      }
    })
  }
  //stopSearchData

  // @ts-ignore
  const Web = <div id="MyNft" className="w-100 text-white" style={{background:'#343439',}}  mode="web">
    <Navigation></Navigation>
    <div className='flexcc w-100 py-140' >
      <div className="w-r-1280" >
    <div className="px-224 ">
      <div className="flexsb">
        <div className="f-32 f-w-9">NFTs</div>
        <div className="f-14 f-w-4 mb-14 flexcc" onClick={()=>skip('/transactionRecord')} >NFT transaction record <RightOutline /></div>
      </div>
      <div  className="d-flex flex-wrap  align-content-start px-216 pb-115" style={{ height: `${remainingHeight}px`, overflow: 'auto',background:'#343439' }}>
          {
            MyNftListPc.length>0? MyNftListPc.map((item: any,index: Key | null | undefined)=>{
              return <div
              key={index}
              className=" w-r-132 h-r-141 mb-16 br-18 mx-4"
              >
              <div className="position-relative  w-r-132 h-r-141 ">
                  <img className="position-absolute  w-r-132 h-r-141 br-12" src={item.imgUrl} style={{zIndex:21}} />
                  <div className="pb-20 position-absolute  w-r-132 h-r-141 br-12 text-white f-14 f-w-4 text-center d-flex justify-content-center  align-items-end"   style={{
                    zIndex: 22,
                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(255, 255, 255, 0.00) 50%)"
                  }}>
                    { JSON.parse(item.metaData).name}
                  </div>
              </div>
            </div>
            }):<div className='text-center pt-200'>
            <div className='f-14'>Here is no data</div>
            <img
                className="w-r-180 h-r-172"
                src={require('@/assets/web/noData.png')}
              />
          </div>
          }
          </div>
      </div>
    </div>
    </div>
  </div>
  // @ts-ignore
  const Mobile = <div id="MyNft" mode="mobile">
    <Navigation></Navigation>
    <div className="nftsBox px-14 py-19" id="nftsBox">
      <div className="flexsb">
       <div className="f-26 f-w-7 mb-14">NFTs</div>
       <div className="f-16 f-w-4 mb-14" onClick={()=>skip('/transactionRecord')} >NFT transaction record <RightOutline /></div>
      </div>
      <div className="flexsb mt-10">
        <div className="w-r-242 h-r-36 flexc px-10" style={{borderRadius: "10px 0px 0px 10px",border: "1px solid #616161"}}>
          <Input placeholder='Search TokenId' value={message}
            style={{ '--color': '#fff' }}
            onChange={val => {
              setMessage(val)
            }}
          />
        </div>
        <div  onClick={() =>{
          getMyNft(1)
        }} className="search flexcc"><SearchOutline fontSize={20}/></div>
      </div>
    </div>
    <div  className="px-14 d-flex flex-wrap justify-content-between align-content-start" style={{ height: `${remainingHeight}px`, overflow: 'auto' }}>
        {
          MyNftList.map((item: any,index: Key | null | undefined)=>{
            return <div onClick={() => skip('/Transfer')} className="position-relative w-r-132 h-r-141 mb-16 bg-18" key={index}>
            <img className="w-100 h-100 br-12" src={item.imgUrl} />
            {/* <div className="EvangelionBg text-white text-center pb-8">
              <div className="f-20 f-w-7 mb-8">Sheep 1</div>
              <div className="f-14 f-w-4">#123456</div>
            </div> */}
          </div>
          })
        }
        <div className="w-100">
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
          
    </div>
  
    


  </div>
  return Common.isMobile ? Mobile : Web
}