/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Space, Swiper, Collapse, Radio, Popup ,SpinLoading,Toast ,Mask} from 'antd-mobile'
import { CloseOutline, DownOutline, RightOutline } from 'antd-mobile-icons'

CMaks.defaultProps = {
  visible: false,
}
export function CMaks(props:any) {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  useEffect(() => {
    setMaksVisible(props.visible)
  }, [props.visible])

  const [maksVisible, setMaksVisible] = useState<boolean>(props.visible)
  // @ts-ignore
  const Web = <div id="CMaks" mode="web"></div>
  // @ts-ignore
  const Mobile = <div id="CMaks" mode="mobile">
     <Mask visible={maksVisible} opacity={0.7} onMaskClick={() => setMaksVisible(false)}>
        <div  className='w-100 min-vh-50 flexcc'>
          <div>
            <div className='flexcc'> <SpinLoading   color='white' style={{ '--size': '48px' }} /></div>
            <div className='mt-20 text-white'>Trigger paypal payment...</div>
          </div>
        </div>
      </Mask>
  </div>
  return Common.isMobile ? Mobile : Web
}