/* eslint-disable @typescript-eslint/no-unused-vars */
import './editMyMsg.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Input, Toast, Modal, ImageUploader } from 'antd-mobile'
import { Input as PCInput,Upload  } from 'antd';
import { LoadingOutlined, CameraOutlined } from '@ant-design/icons';
import { To, useNavigate } from 'react-router-dom'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { init,memberAsync } from '@/slices/user'
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { httpGet, httpPost ,host_url} from '@/utils/httpUtils'
import { PictureOutline } from 'antd-mobile-icons'

import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

export function EditMyMsg() {
  const dispatch = useAppDispatch() 
  const {member}=useAppSelector((state:State)=>state.user)
  const navigate = useNavigate()
  const skip = (url: To) => {
    navigate(url)
  }
  useEffect(() => {
    setUrl( member.headImg?member.headImg:require('@/assets/mobile/my/h.png'));
     dispatch(memberAsync())
  }, [])


  const [message, setMessage] = useState(member.userName)

  const [url, setUrl] = useState('')

  const editData =  async() => {
    debugger
    await httpPost('/member/uptData', {
      headImg:url,
      userName:message
    }).then(async (res:any) => {
      const {data}=res
      if(data.code===0){
       await dispatch(memberAsync())
        skip('/my')
      }
    })
  }

  function onChangeBanner(info: any) {
		if (info.file.status === 'done' || info.file.status === 'removed') {
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if (item.response) {
            setUrl(item.response.url);
				}
			}
		}
	}

  // @ts-ignore
  const Web = <div id="EditMyMsg" mode="web">
      <Navigation ></Navigation>
      <div className='flexcc w-100 pt-140' >
        <div className="w-r-1280" >

      <div className='flexcc text-black mt-60'>
        <div className='w-r-408'>
          <div className='f-32 f-w-9 text-center'>Edit Avatar </div>
          <div className='flexcc mt-24'>
            <Upload
              name="file"
              action={host_url + '/api/app/oss/upload'}
              className="avatar-uploader"
              showUploadList={false}
              onChange={onChangeBanner}
            >
              <img src={url} alt="avatar" style={{ width: '100%' }} />
            </Upload>
          </div>
          
          <div className="w-100 h-r-52 br-12 flexc px-20 mb-28  mt-66 bg-xitong1">
            <PCInput
              allowClear={true} value={message} bordered={false}
              placeholder="Enter Nickname"
              onChange={(val) => {
                setMessage(val.target.value)
              }}
            ></PCInput>
          </div>
          <div
            onClick={() => editData()}
            className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-white"
            style={{ background: '#9754F0' }}>
            Confirm
          </div>
        </div>
      </div>
      </div>
      </div>
  </div>
  // @ts-ignore
  const Mobile = (<div id="EditMyMsg" mode="mobile">
      <Navigation isLogo={false} title="AIPastor"></Navigation>
      <div className="edit px-14 py-34">
        <div className="f-26 f-w-7 mb-21">Chat history</div>
        <div className="flexcc">
          <div className='flexcc mb-24'>
              <Upload
                name="avatar"
                action={host_url + "/api/app/oss/uploadImg"}
                className="avatar-uploader"
                showUploadList={false}
                onChange={onChangeBanner}
              >
                <img src={url} alt="avatar" 
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      backgroundColor: '#f5f5f5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#999999',
                    }}
                />
              </Upload>
            </div>
        </div>
        <div className="w-100 h-r-52 br-12 flexc px-20 mb-28  mt-80 bg-xitong1">
          <Input
            placeholder="Enter Nickname"
            value={message}
            style={{ '--color': '#000' }}
            onChange={(val) => {
              setMessage(val)
            }}
          />
        </div>
        <div
          onClick={() => editData()}
          className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-white"
          style={{ background: '#9754F0' }}>
          Confirm
        </div>
      </div>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
