
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { init,clear,initMember,memberAsync } from '@/slices/user'
import { useEffect, useState } from 'react'

import {
  Outlet, useLocation
} from "react-router-dom"
import './FirstPage.css'

export function FirstPage() {
  window.scrollTo(0, 0);

  const token = useAppSelector(state => state.user.token)
  const dispatch = useAppDispatch() 
  useEffect(() => {
    if(!token && localStorage.getItem("token")){
      dispatch(init({
        token:localStorage.getItem("token") as string,
      }))
      dispatch(memberAsync())
    }
  }, [token])

  

  const location = useLocation();
  const pathname = location.pathname;//pathname路径
  // @ts-ignore
  const Web = <div id="FirstPage" mode="web"  >
    <Outlet></Outlet>
  </div>

  // @ts-ignore
  const Mobile = <div id="FirstPage" mode="mobile" >
    <Outlet></Outlet>
  </div>
  return Common.isMobile ? Mobile : Web
}

