/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @typescript-eslint/no-unused-vars */
import "./PayToSupport.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Space, Swiper, Collapse,Radio } from 'antd-mobile'
import { Footer } from '@/ui/components/Footer'
import { httpGet, httpPost } from "@/utils/httpUtils";
import { Collapse as PCCollapse } from 'antd';
import { Qa } from '@/ui/components/Qa'

import { Paypal }  from "./Paypal"

export function PayToSupport() {
  const { Panel } = PCCollapse;
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  const [visibleBuy, setVisibleBuy] = useState<boolean>(false)
  const [buyID, setBuyID] = useState("")


  const [purchaseDescriptionList , setPurchaseDescriptionList] = useState<any[]>([])

  useEffect(() => {
    httpGet("/memberrecharge/getRechargeConfigList").then((res:any) => {
      const {data}=res
      if(data.code === 0) {
        setPurchaseDescriptionList(data.data)
      }
    })
  }, [])

  // @ts-ignore
  const Web = <div id="PayToSupport" className="text-white"  mode="web">
    <Navigation></Navigation>
    <div>
      <div className="w-100 " style={{
        backgroundImage: `url(${require('@/assets/web/nfts/nfts.png')})`,
        backgroundSize: '100% 100%',
        height:"34.8958vw"
      }}>
        <div className="h-100 " style={{
          background: "linear-gradient(86deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%)",
          backdropFilter: "blur(3px)",
          paddingTop:"9.4271vw",
          paddingLeft:"16.6667vw"
          
        }}>
          <div className="f-60 f-w-9 line-height-70">PAY TO <br/> SUPPORT</div>
          <div className="w-r-466 f-16 f-w-5 my-24">
            Using new tech to help people know God. Self-funded AI companions for theological questions. Support us: buy NFTs or share with friends!
          </div>
          <div className="flexc">
              <div  onClick={()=>{
                navigate("/BlindBox")
              }} className="w-r-140 h-r-52 br-10 flexcc bg-xitong f-16 f-w-7 mr-16 shouHover shou">Get NFT</div>
              <div className="w-r-178 h-r-52 br-10 flexcc  f-16 f-w-7 shouHover" style={{background:'#3C2365'}}>Share with friend</div>
          </div>
        </div>
      </div>

      <div className="px-149 py-60">
        <div>
          <div className="text-center f-32 f-w-9">Subscription</div>
          <div className="f-16 f-w-4 mt-24 mb-61 text-center">Have more conversation needs? Choose the options below!</div>
          <div className="flexcc">
            { purchaseDescriptionList.filter(i=>i.type===0).map((item,index) => {
                return <div className={index==2 ?'mx-10 payToSupport_bg':'mx-10'} style={{	cursor:"pointer"}}>
                      <div key={index} className="w-r-276 br-12  p-6 " style={{border: "1px solid #616161"}}>
                      <div className="f-14 f-w-4 text-center" style={{color:index == 1? '#E89129':'#616161'}}>Level {index}</div>
                      <div className="f-20 f-w-7 text-center">{item.amount==0?'Free':'$'+item.amount}</div>
                      <div className="text-left f-14 f-w-4 mt-17 mb-45 ">
                          {
                            item.productDetail.split(',').map((j:string,i:number) =>{
                              return <div  className="flexc mt-5" >
                                 <img  style={{width:"14px",height:"14px",marginRight:"5px"}} 
                                 src={require(index==2?'@/assets/web/nfts/group_active.png':'@/assets/web/nfts/group.png')} alt="" />
                                 <div className="mb-5" key={i}> {j}</div>
                              </div>
                               
                            })
                          }
                      </div>
                      <div onClick={() =>{
                        setVisibleBuy(true)
                        setBuyID(item.id)
                      }} className={index == 1? 'h-r-51 br-12 flexcc f-16 f-w-7 putitem_jin shouHover shou':'h-r-51 br-12 flexcc f-16 f-w-7 putitem shouHover shou'}  >Buy</div>
                    </div>
                </div>
              })
            }
          </div>
        </div>
        
        <div className="mt-140">
          <div className="text-center f-32 f-w-9 mb-38">The Blind box</div>
            { purchaseDescriptionList.filter(i=>i.type===1).map((item,index) => {
                return <div className="flexcc">
                      <div className="w-r-259 h-r-259 position-relative mr-94" >
                        <div className="w-100 h-100 position-absolute l-0" style={{
                          borderRadius: "20px",
                          background: "radial-gradient(552.86% 132.85% at 18.77% 6.25%, rgba(140, 165, 255, 0.50) 0%, rgba(205, 164, 246, 0.50) 50.52%, rgba(184, 112, 252, 0.50) 50.53%, rgba(254, 159, 194, 0.50) 100%)",
                          filter: "blur(40px)"
                        }}></div>
                        <img className="w-100 h-100 position-absolute l-0" src={require('@/assets/web/nfts/box.png')} /> 
                        <div className="position-absolute h-100 w-100  d-flex flex-column justify-content-between" style={{zIndex:12}}>
                          <div className="text-left px-12 w-100 h-r-28 flexc" style={{
                            borderRadius: "12px",
                            background: "rgba(0, 0, 0, 0.15)"
                          }}>Card NFT</div>
                          <div className="flexsb w-100 h-r-57 px-12" style={{
                            borderRadius: "12px",
                            background: "linear-gradient(4deg, rgba(0, 0, 0, 0.80) 0%, rgba(217, 217, 217, 0.00) 99.99%, rgba(217, 217, 217, 0.00) 100%)"
                          }}>
                            <div className="f-12 f-w-4"></div>
                            <div className="f-18 f-w-7"> ${item.amount}</div>
                          </div>
                        </div> 
                      </div>
                      <div className="d-flex flex-column  w-r-259 h-r-259 justify-content-between">
                        <div className="text-left f-14 f-w-4 w-r-320">
                            {
                              item.productDetail.split(',').map((j:string,i:number) =>{
                                return <div className="mb-5 dian" key={i}> <img  style={{width:"14px",height:"14px"}} src={require('@/assets/web/nfts/group_active.png')} alt="" /> {j}</div>
                              })
                            }
                        </div>
                        <div className="h-r-52 w-r-293 br-12 flexcc f-16 f-w-7 bg-xitong shouHover shou" onClick={()=>skip('/BlindBox')}>Buy</div>
                      </div>
                </div>
              })
            }
        </div>

      </div>

      <div className='flexcc w-100 bg-white' >
        <div className="w-r-1280" >
              <Qa></Qa>
       {/* Q&A */}
              {/* <div className="px-231 py-60  text-black">
                  <div className="flexsb mt-69">
                    <div className="f-26 f-w-7 text-black">Q&A</div>
                    <div className="f-14 f-w-4" style={{color:'#666666'}}>{"more >"}</div>
                  </div>
                  <PCCollapse defaultActiveKey={['2']} ghost={true} expandIconPosition="end">
                    <Panel header="Here is a title here is a title here is a title here is a title here is a title" key="1">
                      <p>1212</p>
                    </Panel>
                    <Panel header="Here is a title here is a title here is a title here is a title here is a title" key="2">
                      <div className="flexsb f-14 f-w-4">
                          <div className="dian w-75">You can purchase conversation...</div>
                          <div className="w-25 text-right" style={{color:'#AC76F3'}}>See more</div>
                      </div>
                    </Panel>
                  </PCCollapse>
                </div> */}


        </div>
        </div>
    </div>
     {/* PaybyCrypto */}
     <Paypal visible={visibleBuy}  onClose={() => {
        setVisibleBuy(false)
      }} id={buyID}></Paypal>
    <Footer></Footer>
  </div>
  // @ts-ignore
  const Mobile = <div id="PayToSupport" mode="mobile">
    <Navigation></Navigation>
    
    <div>
      <div className="PayToSupportH position-relative">
         {/* <img className="w-r-280 h-r-284 br-12" src={require('@/assets/mobile/nfts/nftbay.png')} /> */}
         {/* <img className="w-100 h-100 " src={require('@/assets/mobile/nfts/nftbay.png')} /> */}
         <img className="w-r-202  h-r-20  position-absolute  b-40 l-20" src={require('@/assets/mobile/nfts/pay.png')} />
      </div>
      <div className="px-23 pt-64">
          <div className="text-center f-32 f-w-9">Subscription</div>
          <div className="mt-10 mb-40 text-center f-16 f-w-4">
          Have more conversation needs? Choose the options below!
          <br/>
          We are exploring the use of new tech to help more people to know God. Currently, using AI to accompany and answer theological questions is mainly self-funded to cover the high costs. If you would like to help, welcome to purchase NFTs or share with your friends!
          </div>
      </div>
      <div className="px-23 text-center">
        {
         purchaseDescriptionList.map((item,index) => {
            return <div>
                { item.type === 0?
                <div key={index}  className={index==2 ?'w-100 br-12 mb-14 p-14 payToSupport_bg':'w-100 br-12 mb-14 p-14'}  style={{border: "1px solid #616161"}}>
                  <div className="f-14 f-e-4 " style={{color:'#616161'}}>Level {index}</div>
                  <div className="f-20 f-w-7">${item.amount}</div>
                  <div className="text-left f-14 f-w-4 mt-17 mb-45">
                      {
                        item.productDetail.split(',').map((j:string,i:number) =>{
                          return <div className="mb-5 dian" key={i}>
                            <img  style={{width:"14px",height:"14px",marginRight:"5px"}} 
                                 src={require(index==2?'@/assets/web/nfts/group_active.png':'@/assets/web/nfts/group.png')} alt="" />
                            {j}</div>
                        })
                      }
                  </div>
                  <div onClick={() =>{
                    setVisibleBuy(true)
                    setBuyID(item.id)
                  }} className="h-r-51 br-12 flexcc f-16 f-w-7" style={{border: "1px solid #616161"}}>Get</div>
                </div>
                
                :
                
                <div className="w-100 br-12 mb-14 p-14" key={index} style={{border: "1px solid #616161"}}>
                    <div className="payNft position-relative" >
                    <img className="w-100 h-100 position-absolute l-0" src={require('@/assets/mobile/nfts/payNft.png')} /> 
                    <div className="position-absolute h-100 w-100 py-7 px-12 d-flex flex-column justify-content-between" style={{zIndex:12}}>
                        <div className="text-left">Card NFT</div>
                        <div className="flexsb">
                          <div className="f-12 f-w-4">120 times</div>
                          <div className="f-18 f-w-7">${item.amount}</div>
                        </div>
                      </div> 
                    </div>
                    <div className="text-left f-14 f-w-4 mt-17 mb-45">
                      {
                        item.productDetail.split(',').map((j:string,i:number) =>{
                          return <div className="mb-5 dian" key={i}>
                            <img  style={{width:"14px",height:"14px",marginRight:"5px"}} 
                                 src={require('@/assets/web/nfts/group_active.png')} alt="" />
                            {j}</div>
                        })
                      }
                  </div>
                  <div className="h-r-51 br-12 flexcc f-16 f-w-7 bg-xitong" onClick={()=>skip('/BlindBox')}>Get</div>
                </div>
                }
            </div>
          })
        }
      </div>
    
      <div className="p-15 bg-white">
               <Qa></Qa>
      </div>
    </div>
      {/* PaybyCrypto */}
      <Paypal visible={visibleBuy}  onClose={() => {
        setVisibleBuy(false)
      }} id={buyID}></Paypal>
    <Footer></Footer>
  </div>
  return Common.isMobile ? Mobile : Web
}