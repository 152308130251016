import copy from 'copy-to-clipboard';
import {  Toast } from 'antd-mobile'
const mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Symbian"];
let userAgent = navigator.userAgent;


window.addEventListener('resize', handleResize)
function handleResize() {
	let flag = mobileAgents.some(ag => navigator.userAgent.includes(ag));
	if (flag !== Common.isMobile) {
		window.location.reload();
	}
}


export const Common = {
	isMobile: mobileAgents.some(ag => userAgent.includes(ag)),
	tg() {
		window.open('https://twitter.com/lumilovegod', '_block');
	},
	dc() {
		window.open('https://discord.gg/lumi-christianity-gospel-bible-pray-jesus-christ-1009369467324735548', '_block');
	},
	toTwitter() {
		debugger
		window.open('https://twitter.com/lumilovegod', '_block');
	},
	email() {
		copy("a@lumi.top");
		Toast.show({
		  icon: 'success',
		  content: 'Copy Success',
		})
	},
	closeNavigationTab() {
		
	}

}
