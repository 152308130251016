/* eslint-disable @typescript-eslint/no-unused-vars */
import './TransactionRecord.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Back } from '@/ui/components/Back'
import { Tabs,InfiniteScroll,DotLoading,Input } from 'antd-mobile'
import { To, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '@/utils/httpUtils'

export function TransactionRecord() {

  const navigate = useNavigate();
  const skip = (url: To,item:any) => {
    navigate(url,{
      state:item
    })
  }

 


  //searchData
  const [message,setMessage] = useState("")
  const [hasMore, setHasMore] = useState(true)
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>--- Loading completed ---</span>
        )}
      </>
    )
  }
  const [transactionRecordList, setTransactionRecordList] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const loadMore = async () => {
    await setPage((page) => {
      page++
      return page
    })
    await getMyNft()
  }
  const getMyNft = async (newPage?:number) => {
    await httpPost("/nftDetail/getNftTransferPage",{page:newPage?newPage:page,limit:10}).then((res:any) => {
      const {data}=res;
      if(newPage) setPage(1);
      if(data.code === 0) {
        setTransactionRecordList((val: any) => (newPage ==1 || page==1 )?[...data.data.list] : [...val, ...data.data.list])
        data.data.list.totalCount === transactionRecordList.length ? setHasMore(true):setHasMore(false) 
        console.log(data.data.list.totalCount === transactionRecordList.length );
      }
    })
  }
  //stopSearchData

  const [transactionRecordListPC, setTransactionRecordListPC] = useState<any[]>([])
  useEffect(() => {
    getMyNftPC()
  }, [])
  const getMyNftPC = async () => {
    await httpPost("/nftDetail/getNftTransferPage",{page:1,limit:10}).then((res:any) => {
      const {data}=res;
      if(data.code === 0) {
        setTransactionRecordListPC([...data.data.list])
      }
    })
  }



  // @ts-ignore
  const Web = <div id="TransactionRecord"  className='text-black' mode="web">
      <Navigation></Navigation>
      <Back></Back>
      <div className='px-424 py-33 pt-140'>
        <div className='f-32 f-w-9'>NFT transaction record</div>
        <div>
          {
              transactionRecordListPC.map((item,index) => {
                return <div className='w-100 p-20' style={{background:index%2==1?'#fff':'#F1F1F1'}} key={index}>
                    <div className='my-8'>
                      <div className='f-16 f-w-7' style={{color:'#000'}}>From:</div>
                      <div className='f-12 f-w-4' style={{color:'#343439'}}>{item.fromAddres}</div>
                    </div>
                    <div className='my-8'>
                      <div className='f-16 f-w-7' style={{color:'#000'}}>To:</div>
                      <div className='f-12 f-w-4' style={{color:'#343439'}}>{item.toAddres}</div>
                    </div>
                    <div className='flexsb'>
                      <div className='f-14 f-w-4' style={{color:'#894CDA'}}>{item.date}</div>
                    </div>
                </div>
              })
            }
          </div>
      </div>
     
  </div>
  // @ts-ignore
  const Mobile = (<div id="TransactionRecord" mode="mobile">
      <Navigation></Navigation>
      <div className="transactionRecordBox px-14 py-34">
        <div className="f-26 f-w-7 mb-30">Transaction Record</div>
        {
          transactionRecordList.map((item,index) => {
            return <div className='w-100 py-20' style={{borderBottom:'1px solid #E0E0E0'}} key={index}>
                {/* <div className='flexsb'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>To</div>
                  <div onClick={()=>skip('/transactionRecordMsc')} className='f-14 f-w-4'  style={{color:'#9754F0'}}>{item.toAddres}</div>
                </div> */}
                <div className='d-flex justify-content-between'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>To</div>
                  <div onClick={()=>skip('/transactionRecordMsc',item)}  className='f-14 f-w-4 w-70   text-right' style={{color:'#9754F0',wordWrap:"break-word" }}>{item.toAddres}</div>
                </div>
                <div className='flexsb my-8'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>On-chain transaction record</div>
                  <div className='f-14 f-w-4' style={{color:'#343439'}}>{item.record}</div>
                </div>
                <div className='flexsb'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>Time</div>
                  <div className='f-14 f-w-4' style={{color:'#343439'}}>{item.date}</div>
                </div>
            </div>
          })
        }
        <div className="w-100">
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
