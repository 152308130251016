/* eslint-disable @typescript-eslint/no-unused-vars */
import "./addChat.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Modal,Input  } from 'antd';
import { CloseOutline } from 'antd-mobile-icons'
GetNft.defaultProps = {
  visible: false,
  onClose: (title: any) => { },
}

export function GetNft(props:any) {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  const getNFT = () => {
    props.onClose()
    skip('/NftHome')
  }
  // @ts-ignore
  const Web = <div id="GetNft" mode="web">
       <Modal
        width={"25vw"}
        visible={props.visible}
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0'}}>
         <div
            className="flexsb pt-20 px-14"
            >
            <div className="f-26 f-w-7"></div>
            <CloseOutline onClick={() => props.onClose()} className="f-20" />
          </div>
          <div className="px-10 py-20 text-center">
            <img className="w-r-90 h-r-90 mb-13" src={require('@/assets/mobile/chat/y.png')} />
            <div className="f-20 f-w-7 text-center mb-22 px-20">You have not set a personalized AI pastor</div>
            <div className="f-14 f-w-4 text-center">Get a sheep NFT to create your chat with personalized AI pastor. What is NFT?</div>
          </div>
          <div className="px-14">
            <div onClick={() =>getNFT()} className="w-100 h-r-52 br-12  flexcc f-16 f-w-7 text-white shou" style={{ background: '#9754F0' }}>
              Get a sheep NFT 
            </div>
            <div onClick={props.onClose} className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-black shou" >
            close
            </div>
            
          </div>

      </Modal>
  </div>
  // @ts-ignore
  const Mobile = <div id="GetNft" mode="mobile">
  </div>
  return Common.isMobile ? Mobile : Web
}