/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Transfer.scss"
import { Common } from "@/app/Common";
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import { Input, Toast, Modal } from 'antd-mobile'
export function Transfer() {
	const navigate = useNavigate();
	const skip = (url: To) => {
		navigate(url)
	}

	const [message, setMessage] = useState('')


	// @ts-ignore
	const Web = <div id="Transfer" mode="web"  >
	</div>

	// @ts-ignore
	const Mobile = <div id="Transfer" mode="mobile" >
		<Navigation isLogo={false} title="AIPastor"></Navigation>
		<div className="px-14 py-34">
			<div className="f-26 f-w-7 mb-26">Transfer</div>
			<div className="w-100 h-r-52 br-12 flexc px-20 mb-24" style={{ background: '#F1F1F1' }}>
				<Input placeholder='Enter arb address' value={message}
					style={{ '--color': '#000' }}
					onChange={val => {
						setMessage(val)
					}}
				/>
			</div>
      <div className="flexsb mt-17 mb-29 f-16 f-w-4" style={{color:'#343439'}}>
        <div>Pay gas fee</div>
        <div>10 USDT</div>
      </div>
			<div onClick={() => skip('/')} className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-white" style={{ background: '#9754F0' }}>
          Paypal
			</div>
		</div>
	</div>
	return Common.isMobile ? Mobile : Web
}

