
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
export function Back() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  const back = () => {
    window.history.back()
  }
  // @ts-ignore
  const Web = <div id="Back" mode="web">
    <div className="f-14 f-w-4 flexcc shou" onClick={()=>back()} style={{
      position: 'fixed',
      top: '10.9375vw',
      right:'7.6875vw'
    }}>
      <LeftOutlined  className=" mr-5" />
      Back
    </div>
  </div>
  // @ts-ignore
  const Mobile = <div id="Back" mode="mobile">
  </div>
  return Common.isMobile ? Mobile : Web
}



