/* eslint-disable @typescript-eslint/no-unused-vars */
import "./addChat.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Modal,Input  } from 'antd';
import { CloseOutline } from 'antd-mobile-icons'
import { httpGet, httpPost} from '@/utils/httpUtils';
HistoryChat.defaultProps = {
  visible: false,
  onClose: (title: any) => { },
}

interface HistoryChatProps {
  visible: boolean;
  onClose: (title: any) => void;
}


export function HistoryChat(props:HistoryChatProps) {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  useEffect(() => {
    if(!props.visible) return
    httpGet("/chatsession/getList").then((res:any) => {
      const {data}=res
      if(data.code === 0) {
        setHistoryData(data.data)
      }
    })
  }, [props.visible])
  
  const [historyData,setHistoryData] = useState<any[]>([])

  // @ts-ignore
  const Web = <div id="HistoryChat" mode="web">
       <Modal
        width={"25vw"}
        visible={props.visible}
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0'}}>
         <div
            className="flexsb pt-20 px-14"
            style={{ borderBottom: '1px solid #F3F3F3' }}>
            <div className="f-26 f-w-7">Create new chat</div>
            <CloseOutline onClick={props.onClose} className="f-20" />
          </div>
          <div className="px-14 py-44" style={{height:'40vh',overflowY: "auto"}}>
          {
          historyData.map((item,index)=>{
          return  <div  onClick={() => props.onClose(item.id)} className="w-100 h-r-52 br-12 px-12 py-4 mb-24 shou" style={{background:'#F1F1F1'}}>
            <div className="f-16 f-w-7">{item.name}</div>
            <div className="f-12 f-w-4 text-a8">{item.uptDate}</div>
            </div>
        })}
         
          </div>
      </Modal>
  </div>
  // @ts-ignore
  const Mobile = <div id="addChat" mode="mobile">
  </div>
  return Common.isMobile ? Mobile : Web
}