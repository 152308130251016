/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './index.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { request } from 'http'
import { Navigation } from '@/ui/components/Navigation'
import { Footer } from '@/ui/components/Footer'
import { To, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { Carousel } from 'antd';
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "../../../utils/useAnalyticsEventTracker";

ReactGA.initialize("G-T3QN8C9FT8", { gaOptions: { debug: true } });
export function HomePage() {
  const token = useAppSelector(state => state.user.token) 
  const navigate = useNavigate()
  const skip = (url: To) => {
    if (url === "/chat" && !token && !localStorage.getItem("token")) {
      navigate('/login')
      return
  }
  navigate(url)
    navigate(url)
  }
  const [nftList, setNftList] = useState<any>([])
  const {member}=useAppSelector((state:State)=>state.user)
  const getHomeNFT = () => {
    httpPost('/nftDetail/getPage', { page: 1, limit: 20 }).then((res: any) => {
      const { data } = res
      console.log(data)
      if (data.code === 0) {
        const list = data.data.list
        const arr = []
        for (let i = 0; i < list.length; i += 5) {
          arr.push(list.slice(i, i + 5))
        }
        setNftList(arr)
      }
    })
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const gaEventTracker = useAnalyticsEventTracker("browser");
  const browser = () => {
	gaEventTracker("browser");
	ReactGA.event({
      category: "browser",
      action: "once",
      label: "intoweb", // optional
      value: 1, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  useEffect(() => {
    getHomeNFT()
	browser()
  }, [])

  // @ts-ignore
  const Web = (<div id="HomePage" mode="web">
      <Navigation ></Navigation>
      <div className="HomeBg ">
        <div className="f-60 f-w-9 GOD">GOD WITH ME</div>
        <div className="f-16 f-w-7 mt-16">Your AI spiritual companion</div>
        <div onClick={() => {
              if(member&& member.id){
                navigate("/chat")
              }else{
                skip('/Login')
              }
        }
        } className="w-r-140 h-r-52 flexcc bg-xitong f-16 f-w-7 mt-44 br-12 shouHover">
          Free to try
        </div>
      </div>
      {/* Our Product */}
    <div className='flexcc w-100' >
      <div className="w-r-1280" >
          <div className="w-100 px-230 py-60">
            <div className="text-center f-32 f-w-9">Our Product</div>
            <div className="f-16 mt-24 pb-60 font_man">
              24/7 AI companionship & theological answers for all. Web3 integration
              for global Christian support & donations. Building a digital kingdom
              of God.
            </div>
            <div className="flexsb">
              <div className="w-r-285 position-relative">
                <div
                  className="bgColor position-absolute"
                  style={{ top: '-3.75vw' }}></div>
                <img
                  className="w-r-285 h-r-280 position-relative"
                  src={require('@/assets/web/Home/AIPastor.png')}
                />
                <div className="f-26 f-w-7 mt-24 mb-16 ">AI Companion</div>
                <div className="f-16 font_man">
                  Communicate with your AI spiritual companion at any time to
                  resolve the confusion in your life
                </div>
                <div
                  onClick={() => skip('/chat')}
                  className="w-r-140 h-r-52 br-12 flexcc bg-xitong shouHover f-16 f-w-7 mt-24">
                  AI Companion
                </div>
              </div>
              <div className="w-r-285 position-relative">
                <div
                  className="bgColor position-absolute"
                  style={{ top: '-3.75vw' }}></div>
                <img
                  className="w-r-285 h-r-280 position-relative"
                  src={require('@/assets/web/Home/NFT.png')}
                />
                <div className="f-26 f-w-7 mt-24 mb-16">NFT</div>
                <div className="f-16 font_man">
                  Hallelujah NFTs: Own an AI companion and support the Christian
                  digital world in DApp 'Lumi'.
                </div>
                <div
                  onClick={() => skip('/NftHome')}
                  className="w-r-140 h-r-52 br-12 flexcc bg-xitong shouHover f-16 f-w-7 mt-24">
                  Get NFT
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
      {/* Powered by Lumi */}
      <div className="bg-white w-100 py-60 text-black">
      <div className='flexcc w-100' >
        <div className="w-r-1280" >
          <div className="text-center f-32 f-w-9">Powered by Lumi</div>
            <div className="px-228 py-24 f-16 font_man">
              Lumi is a project that using cutting-edge technology to make more
              people know God. AI helps those who don't have access to pastors and
              churches, and Web3 DApp helps Christian around the globe to help,
              donate and pray for each other, building the digital kingdom of God.
            </div>
          </div>
        </div>

        
        {/* <div className="w-100 h-r-280 d-flex" style={{ overflowX: 'auto' }}>
          {nftList.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  index === nftList.length - 1 ? 'br-12 ' : 'mr-20 br-12 shou'
                }>
                <img className="w-r-285 h-r-280 br-12" src={item.imgUrl} />
              </div>
            )
          })}
        </div> */}
         <Carousel  className='h-r-280 w-100' autoplay>
          {nftList.map((item: any, index: number) => {
            return (
              <div className='w-100 d-flex justify-content-center' key={index}>

                {
                  item.map((iItem: any, itemIndex: number) => {
                    return (
                      <div
                      className='mr-20 br-12 shou'
                        key={itemIndex}>
                        <img className="w-r-285 h-r-280 br-12" src={iItem.imgUrl} />
                      </div>
                    )
                  }
                  )
                }
              </div>
            )
          })}
          </Carousel>


        <div className='flexcc w-100' >
        <div className="w-r-1280" >
        <div className="px-250 pt-60">
              <div className="text-center f-32 f-w-9">By the numbers</div>
              <div className="f-16 text-center mt-24 mb-60">
                Already got 1 Million+ Chrisitian followers in 7 Weeks
              </div>
              <div className="flexsb">
                <div>
                  <div className="text-center f-50 f-w-9 GOD ">3000K</div>
                  <div className="text-center f-16  mt-24">
                    Post prayers everyday
                  </div>
                </div>
                <div>
                  <div className="text-center f-50 f-w-9 GOD ">4500K</div>
                  <div className="text-center f-16  mt-24">Bible Verse Sharing</div>
                </div>
                <div>
                  <div className="text-center f-50 f-w-9 GOD ">1800K</div>
                  <div className="text-center f-16  mt-24">subscribers</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer></Footer>
    </div>
  )
  // @ts-ignore
  const Mobile = (<div id="HomePage" mode="mobile">
      <Navigation isOccupy={false}></Navigation>
      <div className="HomeBg flexcc px-16">
        <div>
          <div className="f-60 f-w-9 line-height-73">GOD WITH ME</div>
          <div className="f-16 f-w-7 line-height-27">
            Your AI spiritual companion
          </div>
        </div>
      </div>
      {/* Our Product */}


      <div className="OurProduct">
        <div>
          <div className="text-center f-32 f-w-9">Our Product</div>
          <div className="text-center f-16 f-w-4 mt-24">
            We aim to use AI technology to provide 24/7 online companionship and
            theological answers, especially in areas without access to pastors.
            In the future, we plan to integrate Web3 technology to enable global
            Christian support and donations, building a digital kingdom of God.
          </div>
        </div>
        <div className="mt-60">
          <img
            className="w-100 h-r-280"
            src={require('@/assets/mobile/Home/a.png')}
          />
          <div className="f-26 f-w-7 mt-24 mb-16">AI spiritual companion</div>
          <div className="f-16 f-w-4">
            Communicate with your AI spiritual companion at any time to resolve
            the confusion in your life
          </div>
          <div
            onClick={() => skip('/chat')}
            className="mt-24 w-r-140 h-r-52 br-12 flexcc f-16 f-w-7"
            style={{ backgroundColor: '#894CDA' }}>
            Try
          </div>
        </div>
        <div className="mt-60">
          <img
            className="w-100 h-r-280"
            src={require('@/assets/mobile/Home/b.png')}
          />
          <div className="f-26 f-w-7 mt-24 mb-16">NFT</div>
          <div className="f-16 f-w-4">
            Hallelujah NFTs are inspired by biblical revelation that represent
            your AI-soulmate. By owning one, you can not only have your
            AI-spiritual companion that you can talk to, but also help and
            donate in the dApp 'Lumi'. NFT serves as proof of your
            support/donation towards the construction of Christian digital
            world.
          </div>
          <div
            className="mt-24 w-r-140 h-r-52 br-12 flexcc f-16 f-w-7"
            onClick={() => skip('/NftHome')}
            style={{ backgroundColor: '#894CDA' }}>
            Get NFT
          </div>
        </div>
      </div>



      {/* Powered by Lumi */}
      <div className="Powered">
        {/* Powered by Lumi */}
        <div>
          <div className="text-center f-32 f-w-9">Powered by Lumi</div>
          <div className="text-center f-16 f-w-4 mt-24">
            Lumi is a project that using cutting-edge technology to make more
            people know God. AI helps those who don't have access to pastors and
            churches, and Web3 dApp helps Christian around the globe to help,
            donate and pray for each other, building the digital kingdom of God.
          </div>
          <div className="my-60 position-relative">
            <img
              className="w-100 h-r-280"
              src={require('@/assets/mobile/Home/hua.png')}
            />
            <div className="EvangelionBg text-white text-center">
              <div className="f-20 f-w-7 mb-8">Evangelion</div>
              <div className="f-14 f-w-4">Path of Love</div>
            </div>
          </div>
        </div>
        {/* By the numbers */}
        <div>
          <div className="text-center f-32 f-w-9">Our Progress</div>
          <div className="text-center f-16 f-w-4 mt-24">
            Supported by well-known pastors and Web3 OGs. If you would love to
            be our 'cyber missionary', please contact!
          </div>
          <div className="mt-60">
            <div className="text-center f-50 f-w-9 line-height-61">1 M+</div>
            <div className="text-center f-16 f-w-4 mt-24">
              Christian followers on TikTok
            </div>
          </div>
          <div className="mt-60">
            <div className="text-center f-50 f-w-9 line-height-61">3000K</div>
            <div className="text-center f-16 f-w-4 mt-24">
              Post prayers everyday
            </div>
          </div>
          <div className="mt-60">
            <div className="text-center f-50 f-w-9 line-height-61">4500K</div>
            <div className="text-center f-16 f-w-4 mt-24">
              Bible Verse Sharing
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
