/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { request } from 'http'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { web3 } from '@/app/Config'
import { Popup } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { Modal } from 'antd';
import { State, useAppDispatch, useAppSelector } from '@/app/Store'
import { init,memberAsync } from '@/slices/user'
import { Connector_wallet } from '@/ui/components/Connector_wallet'
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId,
  } from '@/slices/ConnectSlice'
export function Wallet() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch() 
  const [visible, setVisible] = useState<boolean>(false)
	const { address, chainId } = useAppSelector(ConnectSelectors.userData)
  async function sign_wallet() {


    
    const signature = await web3.eth.personal.sign(
      'welcome to lumi ai Login',
      address,
      'welcome to lumi ai Login'
    )
    httpPost('/member/walletLogin', {
      address: address,
      signature: signature,
      message: 'welcome to lumi ai Login',
    }).then((e: any) => {
      if (e.data.code == 0) {
        localStorage.setItem('token',e.data.token)
        dispatch(memberAsync())
        navigate("/");
      }
    })
  }
  // @ts-ignore
  const Web = <div id="Wallet" mode="web">
    <div
        onClick={() => {
          setVisible(true)
        }}
        className="w-r-131 h-r-52 br-12 flexcc shou"
        style={{ border: '1px solid #D9D9D9' }}>
        <img
          className="w-r-20 h-r-20 mr-18"
          src={require('@/assets/mobile/login/wallet.png')}
        />
        <div className="f-12 f-w-4 line-height-18" > wallet </div>
      </div>
      <Modal
      width={"25vw"}
        closable={false}
        visible={visible}
        footer={null}
        bodyStyle={{ height: '40vh',padding:'0'}}>
        <div className='flexsb pt-20 px-14' style={{borderBottom: "1px solid #F3F3F3"}}>
            <div className='f-26 f-w-7'>Connect a wallet</div>
            <CloseOutline  onClick={()=>setVisible(false)} className='f-20' />
        </div>
        <div className='p-14'>
            <div   
              className='w-100 h-r-52 flexc br-12 mt-22 px-10 shou' style={{border: "1px solid #D9D9D9"}}>
              <img className="w-r-32 h-r-32 mr-18" src={require('@/assets/mobile/login/hu.png')} /> 
              <div className='f-18 f-w-6 '  >
                 <Connector_wallet  handleClick={sign_wallet} ></Connector_wallet>
              </div>
            </div>
            <div className='w-100 h-r-52 flexc br-12 mt-22 px-10 shou' style={{border: "1px solid #D9D9D9"}}>
              <img className="w-r-32 h-r-32 mr-18" src={require('@/assets/mobile/login/w.png')} /> 
              <div className='f-18 f-w-6'>WalletConnect</div>
            </div>
        </div>
      </Modal>
      
  </div>
  // @ts-ignore
  const Mobile = ( <div id="Wallet" mode="mobile">
      <div
        onClick={() => {
          setVisible(true)
        }}
        className="w-r-131 h-r-52 br-12 flexcc"
        style={{ border: '1px solid #D9D9D9' }}>
        <img
          className="w-r-20 h-r-20 mr-18"
          src={require('@/assets/mobile/login/wallet.png')}
        />
        <div className="f-12 f-w-4 line-height-18" > wallet </div>
      </div>

      <Popup
        closeOnMaskClick={false}
        visible={visible}
        bodyStyle={{ height: '90vh',borderRadius:'20px 20px 0 0' }}>
        <div className='flexsb pt-20 px-14' style={{borderBottom: "1px solid #F3F3F3"}}>
            <div className='f-26 f-w-7'>Connect a wallet</div>
            <CloseOutline  onClick={()=>setVisible(false)} className='f-20' />
        </div>
        <div className='p-14'>
            <div   
              className='w-100 h-r-52 flexc br-12 mt-22 px-10' style={{border: "1px solid #D9D9D9"}}>
              <img className="w-r-32 h-r-32 mr-18" src={require('@/assets/mobile/login/hu.png')} /> 
              <div className='f-18 f-w-6'  >
                 <Connector_wallet  handleClick={sign_wallet} ></Connector_wallet>
              </div>
            </div>
            <div className='w-100 h-r-52 flexc br-12 mt-22 px-10' style={{border: "1px solid #D9D9D9"}}>
              <img className="w-r-32 h-r-32 mr-18" src={require('@/assets/mobile/login/w.png')} /> 
              <div className='f-18 f-w-6'>WalletConnect</div>
            </div>
        </div>
      </Popup>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
