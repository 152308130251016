/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { SpinLoading } from "antd-mobile";
export function PlayLoading() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  // @ts-ignore
  const Web = <div id="PlayLoading" mode="web"></div>
  // @ts-ignore
  const Mobile = <div id="PlayLoading" mode="mobile">
    <Navigation></Navigation>
    <div>
      <div className='flexcc my-20'> <SpinLoading   color='primary' style={{ '--size': '48px' }} /></div>
      <div className="text-center f-16 f-w-4 text-black">Trigger paypal payment...</div>
    </div>
  </div>
  return Common.isMobile ? Mobile : Web
}