


import {ChainId, web3} from "./Config";
import {AbiItem} from "web3-utils";
import {Contract} from "web3-eth-contract";
import erc20 from "@/contracts/erc20.json";
import lumiProxy_abi from "@/contracts/lumiProxy_abi.json";

let contract: Contract;

export async function getLumiProxy() {
	if (!contract) {
		// @ts-ignore
		contract = new web3.eth.Contract(
			lumiProxy_abi.abi as AbiItem[],
			lumiProxy_abi.address
		);
	}
	return contract;
}

export async function getErc20Contract() {
	// @ts-ignore
	return  new web3.eth.Contract(
		erc20.abi as AbiItem[],
		erc20.address
	);
}

