/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./createChat.scss"
import { Common } from "@/app/Common";
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'
import { Input, Toast, Modal } from 'antd-mobile'
import { httpGet, httpPost,host_url} from '@/utils/httpUtils';

export function CreateChat() {
	const navigate = useNavigate();
	const skip = (url: To) => {
		navigate(url)
	}
	const [message, setMessage] = useState('')
	function createSession() {
		httpGet('/chatsession/create/'+message).then((e: any) => {
		  if (e.data.code == 0) {
			Toast.show({
					icon:"",
					content:"Successfully created conversation"
			  })
			skip('/chat?sessionId='+e.data.data)
		  }
		})
	  }
	


	// @ts-ignore
	const Web = <div id="createChat" mode="web"  >
	</div>

	// @ts-ignore
	const Mobile = <div id="createChat" mode="mobile" >
		<Navigation isLogo={false} title="AIPastor"></Navigation>
		<div className="box">
			<div className="f-26 f-w-7 mb-26">Create new chat</div>
			<div className="w-100 h-r-52 br-12 flexc px-20 mb-24" style={{ background: '#48484D' }}>
				<Input placeholder='Enter title' value={message}
					style={{ '--color': '#fff' }}
					onChange={val => {
						setMessage(val)
					}}
				/>
			</div>
			<div onClick={createSession} className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7" style={{ background: '#9754F0' }}>
				Confirm
			</div>
		</div>
	</div>
	return Common.isMobile ? Mobile : Web
}

