import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {httpGet,httpPost,host_url} from '@/utils/httpUtils';
import { State } from '../app/Store'
interface Icount {
  member: any,
  token?: string
}

const initialState: Icount = {
  member: {},
  token: '',
}
export const CountSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    init: (state, member) => {
      state.token = member.payload?member.payload:state.token
    },
    initMember: (state, member) => {
      state.member = member.payload?member.payload:state.member
    },
    clear: (state) => {
      state.member = {}
      state.token = ''
    }
  },
})
export const memberAsync = () => {
  return async (dispatch: any, getState: any) => {
    httpGet("/member/getMember").then((e:any)=>{
      if(e.data.code==0){
        dispatch(initMember(e.data.data));
      }
    })
  }
}
export const { init,clear,initMember } = CountSlice.actions
export default CountSlice.reducer

// 使用
// import { State, useAppDispatch, useAppSelector } from "@/app/Store";
// import { init,clear,initMember } from '@/slices/user'
// const number = useAppSelector(state => state.count.number) // 获取数据
// const dispatch = useAppDispatch() // 获取dispatch
// dispatch(init({shuju})) // 调用方法
