/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Navigation.css";
import "../css/common.css";
import { Constructor } from "../../utils/TypeUtils";
import { BasePage, getPageSetting } from "../pages/BasePage";
import { Common } from "../../app/Common";
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { request } from "http";
import { Avatar, Popover, Popup } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { Action } from 'antd-mobile/es/components/popover'
import { To, useNavigate } from "react-router-dom";
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { init,clear } from '@/slices/user'



Navigation.defaultProps = {
  isOccupy: true,//是否需要占位
  isLogo:true,//是否有logo
  title:'',//标题
};
export function Navigation(props: any) {
  const dispatch = useAppDispatch()
  const token = useAppSelector(state => state.user.token) 
  const {member}=useAppSelector((state:State)=>state.user)

  let query = new URLSearchParams(useLocation().search)
  let token_gogle = query.get('token')
  useEffect(() => {
    if (token_gogle) {
      localStorage.setItem('token', token_gogle)
    }
  }, [token_gogle])

  const logout = () => {
    dispatch(clear())
    localStorage.removeItem("token")
    setVisible(false)
    skip('/')
  }


   
  function faUserName(name:string){
    if(name){
      return name.substring(0,1).toLocaleLowerCase();
    }else{
      return "L";
    }
  }



    const location = useLocation();
    const pathname = location.pathname;//pathname当前路径
    const navigate = useNavigate();
    const skip = (url: To) => {
      setVisible(false)
      if (pathname === url) return;
      if (url === "/chat" && !token && !localStorage.getItem("token")) {
          navigate('/login')
          return
      }
      navigate(url)
    }


  const shape = <div>
    <div className="mb-16 f-16 f-w-5" onClick={() => skip('/chat')}>Talk to AIPastor</div>
    <div className="mb-16 f-16 f-w-5" onClick={() => skip('/NftHome')}>NFT Marketplace</div>
    <div className="mb-16 f-16 f-w-5" onClick={() => skip('/PayToSupport')}>Pay to Support</div>
    <div className="f-16 f-w-5" onClick={() => skip('/')}>Lumi</div>
  </div>
  const user = <div>
    <div className="mb-16 f-16 f-w-5" onClick={() => skip('/my')}>Me</div>
    <div className="f-16 f-w-5" onClick={logout}>Logout</div>
  </div>


  const [visible, setVisible] = useState(false)
  const [type, setType] = useState("")


  const open = (type:string) =>{
      setVisible(!visible)
      setType(type)
  }

  // @ts-ignore
  const Web = <div id="navigation" mode="web">
    {
      props.isOccupy ? <div className="seizeASeat"></div> : null
    }
    <div className="navigation "  style={{ background: pathname=='/my'?'rgba(31, 31, 31)':'rgba(31, 31, 31, 0.60)' }} >
      <div className="flexcc">
        {
          props.isLogo ? <img  onClick={() => skip('/')} className="w-r-74 h-r-32 shou" src={require('@/assets/web/Nav/logo.png')} /> :<div className="w-r-74 h-r-32"></div>
        }
        <div className="flexcc navList h-100">
            <div className="ml-32 shou" onClick={() => skip('/chat')}>Talk to AI Companion</div>
            <div className="ml-32 shou" onClick={() => skip('/NftHome')}>Hallelujah NFTs</div>
            <div className="ml-32 shou" onClick={() => skip('/PayToSupport')}>Pay to Support</div>
            <div className="ml-32 shou" onClick={() => skip('/')}>Lumi</div>
        </div>
      </div>
      {
          token?<div   className="w-r-52 h-r-52 br-52 flexcc f-16 f-w-7 bg-xitong shou" onClick={() => skip('/my')}>{faUserName(member.userName)}</div> :
          <div className="w-r-140 h-r-52 br-12 flexcc f-16 f-w-7 bg-xitong shou" onClick={() => skip('/login')}>
          Login
        </div>
      }
      
    </div>
  </div>
  // @ts-ignore
  const Mobile = <div id="navigation" mode="mobile">
    {/* seize a seat */}
    {
      props.isOccupy ? <div className="seizeASeat"></div> : null
    }
    <div className="navigation">
      {
        props.isLogo ? <img  onClick={() => skip('/')} className="w-r-55 h-r-24" src={require('@/assets/mobile/Nav/logo.png')} /> :<div className="w-r-55"></div>
      }
      <div className="f-16 f-w-5">{props.title}</div>
      <div className="flexc">
        {
          token?<div onClick={() => open('user')}>
          <img className="w-r-24 h-r-24 mr-23" src={require('@/assets/mobile/Nav/user.png')} />
        </div> :<div className="login" onClick={() => skip('/login')}>login</div>
        }
        <img className="w-r-20 h-r-14" src={require('@/assets/mobile/Nav/Shape.png')} onClick={() => open('shape')} />
      </div>
    </div>

    <Popup
      style={{
        '--z-index': '998'
      }}
      visible={visible}
      onMaskClick={() => {
        setVisible(false)
      }}
      position='top'
      bodyStyle={{ paddingTop: '25vw', paddingBottom: ' 8.75vw', background: '#1F1F1F', color: "#fff" }}
    >
      <div className="pr-20 py-12 pl-54">
        <div className="f-20 text-right" onClick={() => {
          setVisible(false)
        }}><CloseOutline color="#fff" /></div>
        {
          type === 'user'?user:shape
        }
      </div>
    </Popup>
  </div>
  return Common.isMobile ? Mobile : Web
}
