/* eslint-disable @typescript-eslint/no-unused-vars */
import "./addChat.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Modal,Input,message } from 'antd';
import { CloseOutline } from 'antd-mobile-icons'
import { httpGet, httpPost } from '@/utils/httpUtils'

LoginC.defaultProps = {
  visible: false,
  onClose: (title: any) => { },
}



export function LoginC(props:any) {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  // @ts-ignore
  const Web = <div id="addChat" mode="web">
       <Modal
        width={"25vw"}
        visible={props.visible}
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0'}}>
         <div
            className="flexsb pt-20 px-14"
            style={{ borderBottom: '1px solid #F3F3F3' }}>
            <div className="f-26 f-w-7">Please log in</div>
            <CloseOutline onClick={() => props.onClose()} className="f-20" />
          </div>
          
          <div className="px-14 py-44">
              <div  onClick={() => props.onClose('Login')} className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-white shou" style={{ background: '#9754F0' }}>
              Login
              </div>
              <div  onClick={() => props.onClose()}  className="w-100 h-r-52 br-12 mb-24 flexcc f-16 f-w-7 text-white shou" style={{ background: '#FFB257' }}>
              Close
              </div>
          </div>

      </Modal>
  </div>
  // @ts-ignore
  const Mobile = <div id="addChat" mode="mobile">
  </div>
  return Common.isMobile ? Mobile : Web
}