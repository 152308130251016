import { Collapse as PCCollapse } from 'antd';
import { CloseOutline, DownOutline, RightOutline } from 'antd-mobile-icons'

export function Qa() {
	const { Panel } = PCCollapse;
	// @ts-ignore
	const Web = <div className='text-black' >
	<div className="flexsb mt-30">
		<div className="f-26 f-w-7 text-black">Q&A</div>
		<div className="f-14 f-w-4" style={{cursor:"pointer"}} onClick={()=>{
			window.open("https://q3gjsgk9wkf.larksuite.com/wiki/OsNLwdPrhiQE9Pk1yUJuLvk7sJh", '_block')
		}}   >{"more"} <RightOutline></RightOutline>  </div>
	</div>
		<div  onClick={()=>{
				window.open("https://q3gjsgk9wkf.larksuite.com/wiki/Uov7wu8lEiXwpcks0rBuH2hFsPf", '_block')
			}}  className="flexsb f-14 f-w-4  h-r-51">
			<div className="dian w-75 qa">What’s AI companion?</div>
			<div className="w-25 text-right" style={{cursor:"pointer"}} 
			><RightOutline></RightOutline></div>
		</div>

		<div  onClick={()=>{
				window.open("https://q3gjsgk9wkf.larksuite.com/wiki/UAFFwo3bRiYl1CkKtYOuEvBCsDh", '_block')
			}}  className="flexsb f-14 f-w-4 h-r-51">
			<div className="dian w-75 qa">How can I get hallelujah NFTs?</div>
			<div className="w-25 text-right" style={{cursor:"pointer"}} 
			><RightOutline></RightOutline> </div>
		</div>


		<div  className="flexsb f-14 f-w-4  h-r-51" style={{color:"blck"}} >
			<div className="dian w-75">Is it free to use?</div>
			<div className="w-25 text-right" style={{cursor:"pointer"}} 
			 onClick={()=>{
				window.open("https://q3gjsgk9wkf.larksuite.com/wiki/LmOAwAZ1MiTSDPk7MSYuXvzRsOb", '_block')
			}} 
			><RightOutline></RightOutline></div>
		</div>

  </div>

	
	return  Web
}

