/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Nfts.scss";
import { Common } from "@/app/Common";
import { Key, useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Back } from '@/ui/components/Back'
import { Tabs, Input, SpinLoading, Image, Empty } from 'antd-mobile'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { SearchOutline } from 'antd-mobile-icons'
import { Avatar, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
export function Nfts() {

  const [tab, setTab] = useState(0)
  const tabs = [
    { title: 'All', id: 0 },
    { title: 'Sold', id: 1 },
    { title: 'Unsold', id: 2 },
  ]



  useEffect(() => {
    getHomeNFT()
  }, [tab])

  const [message, setMessage] = useState("")
  const [nftList, setNftList] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const [loding, setLoding] = useState(false)
  const [page, setPage] = useState(1)
  const getHomeNFT = () => {
    if(page ===1)setLoding(true);
    setPage((page) =>{
      return page + 1
    })
    console.log(page);
    httpPost("/nftDetail/getPage", { page, limit: 20, soldStatus: tab, tokenId: message }).then((res: any) => {
      const { data } = res
      if(page ===1)setLoding(false);
      if (data.code === 0) {
        setTotalCount(data.data.totalCount)
        setNftList((nftList: any) =>{
          return  page===1?[...data.data.list] : [...nftList, ...data.data.list]
        })
      }
    })
  }


  const [remainingHeight, setRemainingHeight] = useState(0);
  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const headerHeight = document.getElementById('navigation')?.getBoundingClientRect().height || 0;
      const footerHeight = document.getElementById('nftsBox')?.getBoundingClientRect().height || 0;
      const remainingHeight = windowHeight - headerHeight - footerHeight;
      setRemainingHeight(remainingHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // @ts-ignore
  const Web = <div id="Nfts" className="w-100 text-white" style={{ background: '#343439', height: "100vh" }} mode="web">
    
    <Navigation></Navigation>
    <div className='flexcc w-100 pt-140' >
        <div className="w-r-1280" >
        <Back></Back>
    <div id="nftsBox">
      <div className="w-100 h-300 px-228 ">
        <div className="f-32 f-w-9">NFTs</div>
        <div className="flexsb mt-10">
          <div className="w-r-696 h-r-36 flexc px-10" style={{ borderRadius: "10px 0px 0px 10px", border: "1px solid #616161" }}>
            <Input placeholder='Search' value={message}
              style={{ '--color': '#fff' }}
              onChange={val => {
                setMessage(val)
              }}
            />
          </div>
          <div onClick={() => {
            getHomeNFT()
          }} className="w-r-128 h-r-36 bg-xitong flexcc" style={{ borderRadius: "0px 10px 10px 0px" }}><SearchOutline fontSize={20} /></div>
        </div>
        <div className="d-flex my-47">
          {
            tabs.map((item, index) => {
              return <div key={index} className={`py-11 ${tab === index ? 'sel' : 'noSel'} w-r-100 flexcc`} onClick={() => { 
                setPage(()=>{
                  return 1
                })
                setTab(item.id) }}
              >{item.title}</div>
            })
          }
        </div>
      </div>
      {
          loding? <div className="w-100 h-40 flexcc"><SpinLoading style={{ '--size': '48px' }} /></div> : 
          // d-flex flex-wrap  align-content-start
          <div id="scrollableDiv" className=" px-216 pb-115" style={{ height: `60vh`, overflow: 'auto' }}>
            <InfiniteScroll
              className="w-100"
              dataLength={nftList.length}
              next={getHomeNFT}
              hasMore={nftList.length < totalCount}
              loader={<Divider  plain><span className="text-white">Loading... </span> </Divider>}
              endMessage={<Divider plain> <span className="text-white">Loading completed~~</span> </Divider>}
              scrollableTarget="scrollableDiv" >
              <List
                grid={{
                  xs:6,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                  xxl: 6,
                }}
                dataSource={nftList}
                renderItem={(item: any, index: Key | null | undefined) => (
                  <List.Item key={index}>
                    <div
                      key={index}
                      className=" w-r-132 h-r-141 mb-16 br-18 mx-4"
                    >
                      <div className="position-relative  w-r-132 h-r-141 ">
                        <img className="position-absolute  w-r-132 h-r-141 br-12" src={item.imgUrl} style={{ zIndex: 21 }} />
                        <div className="pb-20 position-absolute  w-r-132 h-r-141 br-12 text-white f-14 f-w-4 text-center d-flex justify-content-center  align-items-end" style={{
                          zIndex: 22,
                          background: "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(255, 255, 255, 0.00) 50%)"
                        }}>
                          {JSON.parse(item.metaData).name}
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        }
      </div>
    </div>  
    </div>

  </div>
  // @ts-ignore
  const Mobile = <div id="Nfts" mode="mobile">
    <Navigation></Navigation>
    <div className="nftsBox px-14 py-19" id="nftsBox">
      <div className="f-26 f-w-7 mb-33">NFTs</div>
      <div className="flexsb">
        {
          tabs.map((item, index) => {
            return <div key={index} className={`py-11 ${tab === index ? 'sel' : 'noSel'} w-33 flexcc`} onClick={() => { setTab(item.id) }}
            >{item.title}</div>
          })
        }
      </div>
      <div className="flexsb mt-10">
        <div className="w-r-242 h-r-36 flexc px-10" style={{ borderRadius: "10px 0px 0px 10px", border: "1px solid #616161" }}>
          <Input placeholder='Search' value={message}
            style={{ '--color': '#fff' }}
            onChange={val => {
              setMessage(val)
            }}
          />
        </div>
        <div onClick={() => {
          getHomeNFT()
        }} className="search flexcc"><SearchOutline fontSize={20} /></div>
      </div>
    </div>
    {
      loding ? <div className="w-100 h-40 flexcc"><SpinLoading style={{ '--size': '48px' }} /></div> :
        <div className="px-14 d-flex flex-wrap justify-content-between align-content-start" style={{ height: `${remainingHeight}px`, overflow: 'auto' }}>
          {
            nftList.length > 0 ? nftList.map((item: any, index: Key | null | undefined) => {
              return <div className="position-relative w-r-132 h-r-141 mb-16 bg-18" key={index}>
                <Image className="w-100 h-100 br-12" lazy src={item.imgUrl} />
                {/* <div className="EvangelionBg text-white text-center pb-8">
            <div className="f-20 f-w-7 mb-8">Sheep 1</div>
            <div className="f-14 f-w-4">#123456</div>
          </div> */}
              </div>
            }) : <div className="w-100 h-100 flexcc"><Empty
              style={{ padding: '64px 0' }}
              imageStyle={{ width: 128 }}
              description='No Data'
            /></div>
          }
        </div>
    }




  </div>
  return Common.isMobile ? Mobile : Web
}