/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./chatHistory.scss"
import { Common } from "@/app/Common";
import { Navigation } from '@/ui/components/Navigation'
import { useEffect, useRef, useState } from 'react'
import {To, useNavigate } from "react-router-dom";
import { httpGet, httpPost} from '@/utils/httpUtils';
export function ChatHistory() {



const navigate = useNavigate();
const skip = (url: To) => {
  navigate(url)
}

useEffect(() => {
  httpGet("/chatsession/getList").then((res:any) => {
    const {data}=res
    if(data.code === 0) {
      setHistoryData(data.data)
    }
  })
}, [])

const [historyData,setHistoryData] = useState<any[]>([])


	// @ts-ignore
	const Web = <div id="ChatHistory" mode="web"  >
	</div>
	// @ts-ignore
	const Mobile = <div id="ChatHistory" mode="mobile" >
    <Navigation isLogo={false} title="AIPastor"></Navigation>
    <div className="box">
      <div className="f-26 f-w-7 mb-26">Chat history</div>
      {
        historyData.map((item,index)=>{
          return <div key={index} onClick={() => skip('/chat?sessionId='+item.id)} className="w-100 h-r-52 br-12 px-12 py-4 mb-24" style={{background:'#48484D'}}>
            <div className="f-16 f-w-7">{item.name}</div>
            <div className="f-12 f-w-4 text-a8">{item.uptDate}</div>
          </div>
        })
      }
    </div>
	</div>
	return Common.isMobile ? Mobile : Web
}

