/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { httpGet, httpPost } from "@/utils/httpUtils";
import "./index.scss"
import { Navigation} from '@/ui/components/Navigation'
import { Input, Divider,Toast } from 'antd-mobile'
import { Input as PCInput } from 'antd';

import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import { init,memberAsync } from '@/slices/user'
import { To, useNavigate } from "react-router-dom";
import { Google } from "./google";
import { Wallet } from "./wallet";




export function Login() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }


  const dispatch = useAppDispatch() 


  const[countError,setCountError]=useState<boolean>(true)
  const [count, setCount] = useState<number>(60)
  const [liked, setLiked] = useState<boolean>(false)
  useEffect(() => {
    console.log(count, liked);
    if (count > 0 && liked) {
      setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    } else if (count === 0 && liked) {
      setLiked(false)
    }
  }, [count, liked])



  const [mailbox, setMailbox] = useState<string>("")
  const  EmailCode = () => {
    if(!mailbox){
      return
    }
    if(liked){
      return
    }
    setLiked(true)
    httpGet("/member/sendLonginEmailCaptcha/"+mailbox).then((res:any) => {
      const {data}=res
      // if(data.code == 0){
      //   setLiked(true)
      // }
    })
  }
  const [code, setCode] = useState<string>("")
  const login = () => {
    if(!code || !mailbox){
      return
    }
    httpPost("/member/emailLogin",{
      email:mailbox,
      emailCaptcha:code
    }).then((res:any) => {
      const {data}=res
      if(data.code == 0){
        dispatch(init({
          token:data.token
        }))
        localStorage.setItem('token',data.token)
        dispatch(memberAsync())
        skip('/')
      }else {
        Toast.show({
          icon: 'fail',
          content: data.msg,
        })
        setCountError(false)
      }
    })
  }
  // @ts-ignore
  const Web = <div id="login text-black" mode="web">
    <div className='flexcc w-100'>
      <div className="w-r-1280" >
       <div className="px-494 pt-140"> 
        <div className="f-32 f-w-9">Login</div>
        <div className="f-12 f-w-4 mt-8 mb-24">Login and talk to your AI-pastor</div>
        <div className="h-r-52 w-100 br-12 bg-xitong2 mb-15 px-12 flexc">
          <PCInput allowClear={true} value={mailbox} bordered={false} placeholder='Email' onChange={(e)=>{
            setMailbox(e.target.value)
          }}></PCInput>
        </div>
        <div className="h-r-52 w-100 br-12 bg-xitong2 mb-8 px-12 flexsb" style={{border:(countError? "":"1px solid #A4262C")}}>
            <PCInput placeholder='Code' value={code} bordered={false}  onChange={e => {
            setCode(e.target.value)
            setCountError(true)
          }}></PCInput>
            <div className={`f-14 f-w-4 ml-22 shou ${liked?'text-a8':'text-black'}`} onClick={EmailCode} >Send</div>
        </div>
        {
          countError ?null: <div style={{color:'#A4262C'}}>Error verification code</div>
         }
        {
          liked ?  <div style={{color:'#666666'}}>
          <div>No verification code received?</div>
          <div>resend({count})</div>
        </div> : null
        }
        <div  onClick={login} className={`flexcc w-100 h-r-52  br-12 mt-33 f-16 f-w-7 shou ${code ?'bg-xitong' :'bg-xitong1' }`}style={{color:(code?'#fff':'#B2B3B2')}}>
           Next 
          </div>
        <Divider>OTHER LOGIN METHODS</Divider>
        <div className="flexsb w-100 mt-55">
          <Google></Google>
          <Wallet></Wallet>
        </div>
    </div>
    </div>
    </div>
  </div>
  // @ts-ignore
  const Mobile = <div id="login" mode="mobile">
      <Navigation ></Navigation>
      <div className="login py-34 px-14">
        <div className="f-32 f-w-9">Login</div>
        <div className="f-12 f-w-4">Login and talk to your AI-pastor</div>
        <form className="mt-24">
          <div className="h-r-52 w-100 br-12 bg-xitong2 mb-15 px-22 flexc">
            <Input value={mailbox} placeholder='Email' onChange={val => {
              setMailbox(val)
            }}></Input>
          </div>
          {/*  */}
          <div className="h-r-52 w-100 br-12 bg-xitong2 mb-8 px-22 flexsb" style={{border:(countError? "":"1px solid #A4262C")}}>
              <Input placeholder='Code' value={code} onChange={val => {
              setCode(val)
              setCountError(true)
            }}></Input>
              <div className={`f-14 f-w-4 ml-22 ${liked?'text-a8':'text-black'}`} onClick={EmailCode} >Send</div>
          </div>
          {
          countError ?null: <div style={{color:'#A4262C'}}>Error verification code</div>
         }
          {
            liked ?  <div style={{color:'#666666'}}>
            <div>No verification code received?</div>
            <div>resend({count})</div>
          </div> : null
          }
          <div  onClick={login} className={`flexcc w-100 h-r-52  br-12 mt-33 f-16 f-w-7 ${code ?'bg-xitong' :'bg-xitong1' }`}style={{color:(code?'#fff':'#B2B3B2')}}>
           Next 
          </div>
        </form>
        <Divider>OTHER LOGIN METHODS</Divider>
        <div className="flexsb w-100 mt-55">
          <Google></Google>
          <Wallet></Wallet>
        </div>
      </div>
  </div>
  return Common.isMobile ? Mobile : Web
}