/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Footer.css"
import { Common } from "../../app/Common";
import { Link } from 'react-router-dom'
import {
	TwitterShareButton,
	TwitterIcon,
} from "react-share";


export function Footer() {
	// @ts-ignore
	const Web = <div id="footer" mode="web"   >

			<div className='flexcc w-100' >
       			 <div className="w-r-1280" >
				<div style={{
					padding: "0 1.5625vw"
				}}>

			<div className="w-100 flexsb h-r-156 pr-170" style={{ borderBottom: '1px solid #fff' }}>
				<div className="d-flex">
					<img className="w-r-74 h-r-32 shou mr-134" src={require('@/assets/web/Nav/logo.png')} />
					<div>
						<div className="f-16 f-w-4 mb-16"  onClick={()=>{
							window.open("http://test.godwithme.ai/", '_block')
						}} >AI Companion</div>
						<div  onClick={()=>{
							window.open("http://test.godwithme.ai/#/PayToSupport", '_block')
						}}  className="f-16 f-w-4">Pricing</div>
					</div>
					<div className="ml-100">
						<div className="f-16 f-w-4 mb-16"  onClick={()=>{
							window.open("https://q3gjsgk9wkf.larksuite.com/wiki/EyGHwQn9Mi8PgNkQ08lugU32sob", '_block')
						}} >Contact Us</div>
						<div  onClick={()=>{
							window.open("https://q3gjsgk9wkf.larksuite.com/wiki/UdThwMuo4iSr8QkunUWu12CTsEf", '_block')
						}}  className="f-16 f-w-4">Privacy Policy</div>
					</div>
					<div className="ml-100">
						<div className="f-16 f-w-4 mb-16"  onClick={()=>{
							window.open("http://test.godwithme.ai/#/NftHome", '_block')
						}}  >NFT Marketplace</div>
						<div  onClick={()=>{
							window.open("https://q3gjsgk9wkf.larksuite.com/wiki/UgGrwGGluiHXeMk7nK8ux8ufsRc", '_block')
						}}  className="f-16 f-w-4">Terms of Use</div>
					</div>
				</div>
				<div className="flexcc">
					<img className="w-r-24 h-r-24" src={require('@/assets/mobile/Footer/qiu.png')} />
					<div className="f-16 f-w-4 ml-10">EN</div>
				</div>
			</div>
			<div className="h-r-96 flexc">
				<div className="mr-134 w-r-74 shou">Find us at</div>
				<div    onClick={()=>{
					Common.dc()
				}}  className="flexc">
					<img className="w-r-26 h-r-24 mr-4" src={require('@/assets/mobile/Footer/1.png')} />
					Discord
				</div>
				<div  onClick={()=>{
					Common.toTwitter()
				}}  className="flexc ml-116 shou">
					<img className="w-r-24 h-r-24 mr-4" src={require('@/assets/mobile/Footer/2.png')} />
					Twitter
				</div>
				<div   onClick={()=>{
					Common.email()
				}}  className="flexc ml-120 shou" >
					<img className="w-r-24 h-r-22 mr-4" src={require('@/assets/mobile/Footer/3.png')} />
					Email
				</div>
			</div>
		</div>
		</div>
		</div>
		<div className="w-100 h-r-33 flexcc text-black bg-white">
			Copyright@Lumi 2023
		</div>
	</div>

	// @ts-ignore
	const Mobile = <div id="footer" mode="mobile" >
		<div className="flexsb px-14 pt-60">
			<img className="w-r-73 h-r-32" src={require('@/assets/mobile/Nav/logo.png')} />
			<div className="flexcc">
				<img className="w-r-24 h-r-24" src={require('@/assets/mobile/Footer/qiu.png')} />
				<div className="f-16 f-w-4 ml-10">EN</div>
			</div>
		</div>
		<div className="py-40 px-14" style={{ borderBottom: "1px solid #fff" }}>
			<div className="d-flex flex-wrap">
				<div className="f-16 f-w-4 w-50">God with Me</div>
				<div className="f-16 f-w-4 w-50">Pricing</div>
				<div className="f-16 f-w-4 w-50 my-16">Contact Us</div>
				<div className="f-16 f-w-4 w-50 my-16">NFT Marketplace</div>
				<div className="f-16 f-w-4 w-50">Privacy Policy</div>
				<div className="f-16 f-w-4 w-50">Terms of Use</div>
			</div>
		</div>
		<div className="py-40 px-14">
			<div className="f-16 f-w-4">Find us at</div>
			<div className="d-flex flex-wrap">
				<div  onClick={()=>{
					Common.dc()
				}} className="f-16 f-w-4 w-50 my-16 flexc shou">
					<img className="w-r-22 h-r-16 mr-4" src={require('@/assets/mobile/Footer/1.png')} />
					Discord  
				</div>
				<div   onClick={()=>{
					Common.toTwitter()
				}} className="f-16 f-w-4 w-50 my-16 flexc">
					<img className="w-r-24 h-r-20 mr-4" src={require('@/assets/mobile/Footer/2.png')} />
					Twitter
				</div>
				<div   onClick={()=>{
					Common.email()
				}}   className="f-16 f-w-4 w-50">
					<img className="w-r-20 h-r-16 mr-4" src={require('@/assets/mobile/Footer/3.png')} />
					Email
				</div>
			</div>
		</div>
		<div className="w-100 h-r-46 bg-white flexcc text-black px-14">Copyright@Lumi 2023</div>
	</div>
	return Common.isMobile ? Mobile : Web
}

