/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './BlindBox.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { Space, Swiper, Collapse,Radio,Toast } from 'antd-mobile'
import { PaybyCrypto }  from "./PaybyCrypto"
import { Paypal }  from "./Paypal"
export function BlindBox() {
  const navigate = useNavigate()
  const skip = (url: To) => {
    navigate(url)
  }

  const [nftData, setNftData] = useState<any>({})
  const [visibleBuy, setVisibleBuy] = useState<boolean>(false)
  const [visiblePaypal, setVisiblePaypal] = useState<boolean>(false)
  const [buyID, setBuyID] = useState("")


  useEffect(() => {
    httpGet("/memberrecharge/detectMintNft ").then((res:any) => {
      const {data}=res
      console.log(data);
      if(data.code === 0) {
        setNftData(data.data)
      }
    })
  }, [])

  const pay = (type:string) => {
    if(nftData.number === 0) {
      Toast.show({
        icon: 'fail',
        content: 'is sold out !',
      })
      return;
    }
    if(type === 'paypal') {
      setVisiblePaypal(true)
      setBuyID(nftData.nftConfig.id)
    }else {
      setVisibleBuy(true)
      setBuyID(nftData.nftConfig.id)
    }
  }

  // @ts-ignore
  const Web = <div id="BlindBox" className='text-white'  mode="web">
    <Navigation></Navigation>
    <div className='flexcc w-100  pt-140' >
      <div className="w-r-1280" >
      <div className='px-228 pt-60'>
      <div className='f-32 f-w-9'>Limited Edition NFTs</div>
      <div className='f-16 f-w-4 my-24'>Your AI companion could be a superhero or historical figure who accompanies you in their own unique way, making learning about the Bible more engaging and enjoyable.</div>
      <div className='flexsb'>
        <div className='w-r-276 h-r-309 br-12 p-14  position-relative ' style={{backgroundColor:'#2C2A3F'}}>
          <img className="w-100 h-r-212 " src={require('@/assets/web/HftHome/box.png')} />
          <div className='flexsb mt-9'>
            <div className='text-white f-20 f-w-4'>price</div>
            <div className='f-20 f-w-7'>${nftData?.nftConfig?.amount}</div>
          </div>
          <div className='w-r-265 h-r-8 position-absolute' style={{bottom:'-0.6vw',left:' 0.3906vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
          <div className='w-r-252 h-r-14 position-absolute' style={{bottom:'-1.0938vw',left:'0.7813vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
        </div>
        <div className='h-r-309 pt-20 ml-40' style={{flex:1}}>
          <div className='flexsb mb-40'>
            <div className='w-r-240 h-r-52 br-12 flexcc bg-white flexcc text-black mr-28 shou' 
            onClick={() =>{
              Toast.show({
                icon: 'success',
                content: 'Coming soon',
              })
        }}

            >
              <img className="w-r20 h-r-20 mr-18"  src={require('@/assets/mobile/nfts/p.png')} />Paypal
            </div>
            <div className='w-r-240 h-r-52 br-12 flexcc bg-xitong flexcc shou' onClick={() =>pay('crypto')}>
            <img className="w-r20 h-r-20 mr-18"  src={require('@/assets/mobile/nfts/b.png')} />Crypto
            </div>
          </div>
          <div className='f-26 f-w-7'>Notes</div>
          <div className='f-16 f-w-4 mt-16'>
          1. Only after a successful purchase can you view the image content of the NFT.<br/>
          2. After a successful purchase, the NFT will be sent to your ARB address, and you can check the details in the page of "Me".
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div className='w-r-200 h-r-200 position-fixed r-0 b-0' style={{
      borderRadius: "200px",
      background:" linear-gradient(147deg, rgba(140, 165, 255, 0.20) 0%, rgba(205, 164, 246, 0.20) 50.52%, rgba(184, 112, 252, 0.20) 50.53%, rgba(254, 159, 194, 0.20) 100%)",
      filter: "blur(40px)"
    }}></div>

     {/* PaybyCrypto */}
     <PaybyCrypto visible={visibleBuy}  onClose={() => {
        setVisibleBuy(false)
      }} id={buyID}></PaybyCrypto>
      {/* Paypal */}
      <Paypal visible={visiblePaypal}  onClose={() => {
        setVisiblePaypal(false)
      }} id={buyID}></Paypal>
  </div>
  // @ts-ignore
  const Mobile = ( <div id="BlindBox" mode="mobile">
       <Navigation isLogo={false} title="Hallelujah NFTs"></Navigation>
      <div className="px-20 py-36">
        <div className="f-32 f-w-9 text-center">Limited Edition NFTs</div>
        <div className="f-16 f-w-4 text-center">
        Your AI companion could be a superhero or historical figure who accompanies you in their own unique way, making learning about the Bible more engaging and enjoyable.
        </div>
        {/* NFT */}
        <div className='w-100 h-r-279 br-12 p-14 mb-30 mt-37  position-relative' style={{backgroundColor:'#48484D'}}>
         <img className="w-100 h-r-212 " src={require('@/assets/mobile/nfts/mh.png')} />
         <div className='flexsb mt-9'>
          <div className='text-white f-20 f-w-4'>price</div>
          <div className='f-20 f-w-7'>${nftData?.nftConfig?.amount}</div>
         </div>
         <div className='w-r-265 h-r-8 position-absolute' style={{bottom:'-2.4vw',left:'1.5725vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
         <div className='w-r-252 h-r-14 position-absolute' style={{bottom:'-4.375vw',left:'3.125vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
        </div>
        <div className='flexsb'>
          <div className='w-r-131 h-r-52 br-12 flexcc bg-white flexcc text-black' onClick={() =>{
                Toast.show({
                  icon: 'success',
                  content: 'Coming soon',
                })
          }}
          
          >
            <img className="w-r20 h-r-20 mr-18"  src={require('@/assets/mobile/nfts/p.png')} />Paypal
          </div>
          <div className='w-r-131 h-r-52 br-12 flexcc bg-xitong flexcc' onClick={() =>pay('crypto')}>
           <img className="w-r20 h-r-20 mr-18"  src={require('@/assets/mobile/nfts/b.png')} />Crypto
          </div>
        </div>
        <div className='py-31 position-relative'>
          <div className='position-absolute w-r-210 h-r-210 l-140' style={{filter: "blur(75px)",background: "linear-gradient(0deg, #2435CF, #2435CF)"}}>
          </div>
          <div className='position-relative'  style={{zIndex:9}}>
            <div className='f-26 f-w-7 mb-20'>Notes</div>
            <div className='f-16 f-w-6'>
              <div>
              1. If you do not have a crypto wallet address, Lumi team will help to verify your transaction
              </div>
              <div>
                2. If you have set to transfer the NFT to an ARB address, you will receive your NFT in the ARB wallet address.
              </div>
            </div>
          </div>
        </div>
         {/* PaybyCrypto */}
          <PaybyCrypto visible={visibleBuy}  onClose={() => {
            setVisibleBuy(false)
          }} id={buyID}></PaybyCrypto>
          {/* Paypal */}
          <Paypal visible={visiblePaypal}  onClose={() => {
            setVisiblePaypal(false)
          }} id={buyID}></Paypal>

      </div>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
