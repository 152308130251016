/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @typescript-eslint/no-unused-vars */
import "./PayToSupport.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { Space, Swiper, Collapse, Radio, Popup, SpinLoading, Toast,Button } from 'antd-mobile'
import { CloseOutline, DownOutline, RightOutline } from 'antd-mobile-icons'
import { Footer } from '@/ui/components/Footer'
import { httpGet, httpPost } from "@/utils/httpUtils";
import { Select } from 'antd';
import { State, useAppDispatch, useAppSelector } from "@/app/Store";
import {
  ConnectSelectors,
  ConnectTask,
  disconnect,
  SwitchTask,
} from "@/slices/ConnectSlice";
import {
  CopyOutlined
} from '@ant-design/icons';
import { Input } from 'antd';
import { ChainId, ethereum, web3 } from "@/app/Config";
import { getLumiProxy, getErc20Contract } from '@/app/Contract';
import lumiProxy_abi from "@/contracts/lumiProxy_abi.json";
import { Modal } from 'antd';
import copy from 'copy-to-clipboard';
PaybyCrypto.defaultProps = {
  visible: false,
  id: '',//id
  onClose: () => { },
};
export function PaybyCrypto(props: any) {
  const { TextArea } = Input;

    // 复制内容
    const copyCot = (cot: string) => {
      copy(cot);
      Toast.show({
        icon: 'success',
        content: 'Replicating Success',
      })
    }

  const dispatch = useAppDispatch();
  const { member } = useAppSelector((state: State) => state.user)
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  const { address, chainId } = useAppSelector(ConnectSelectors.userData);
  const isConnected = useAppSelector(ConnectSelectors.isConnected);
  const isLoading = useAppSelector(ConnectSelectors.isLoading);
  async function approveErc20() {

    setLoading_but(true);
    const contract = await getErc20Contract();
    let web_as= web3.utils.toWei(payData.orderAmount + "", "Mwei");
    contract.methods.approve(lumiProxy_abi.address,web_as).send({
      from: address
    }).on('error', (error: any) => {
      //异常
      Toast.show({
        icon: 'fail',
        content: error,
      })
      setLoading_but(false);
      getErc20Approve();
    }).on('transactionHash', (txHash: any) => {
      //点击提交
      console.warn("transactionHash", txHash)
    }).on('receipt', (receipt: any) => {
      //成功
      Toast.show({
        icon: 'success',
        content: 'approve success',
      })
      setLoading_but(false);
      getErc20Approve();
    })
  }

  async function getErc20Approve() {
    if (address) {
      const contract = await getErc20Contract();
      payData.allowance = web3.utils.fromWei(await contract.methods.allowance(address, lumiProxy_abi.address).call()+ "", "Mwei") ;
      console.log(payData.allowance)
      setPayData({ ...payData })
    }
  }

  const options=[
    {
      value:"USDbC",
      label:"USDbC",
    }
  ]

  const [payType, setPayType] = useState<any>('USDbC')
  function connect() {
    !isConnected && !isLoading && dispatch(ConnectTask());
  }
  function switch_() {
    dispatch(SwitchTask())
  }

  const text = isLoading ? "CONNECTING..." :
    isConnected ? chainId === ChainId ?
      "Pay" : "SWITCH" : "CONNECT";

  function textTre(text: any) {
    return "SWITCH,CONNECT".indexOf(text) == -1;
  }


  const onClick = isLoading ? undefined :
    isConnected ? chainId === ChainId ?
      undefined : switch_ : connect;


  const [payData, setPayData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [loading_but, setLoading_but] = useState<boolean>(false)

  async function pay() {
    setLoading_but(true);
    const contract = await getLumiProxy();
    contract.methods.buyNft(payData.orderNo, web3.utils.toWei(payData.orderAmount + "", "Mwei"), address).send({
      from: address
    }).on('error', (error: any) => {
      Toast.show({
        icon: 'fail',
        content: error,
      })
      setLoading_but(false);
    }).on('transactionHash', (txHash: any) => {
      console.warn("transactionHash", txHash);
    }).on('receipt', (receipt: any) => {
      setLoading_but(false);
      Toast.show({
        icon: 'success',
        content: 'buy Nft success',
      })
       navigate("/myNft")
       setLoading_but(false);
    })
  
  }

 function createOrder(){
  httpGet('/memberrecharge/createOrderNO/' + props.id).then(async (res: any) => {
    const { data } = res
    setLoading(false)
    if (data.code === 0) {
      if (address) {
        const contract = await getErc20Contract();
        data.data.allowance = web3.utils.fromWei(await contract.methods.allowance(address, lumiProxy_abi.address).call() + "", "Mwei") ;
      }
      setPayData((payData: any) => ({ ...payData, ...data.data }))
    }
  })
 }

  useEffect(() => {
    if (props.visible && props.id) {
      setLoading(true)
      createOrder();
    }
  }, [props.visible, props.id])

  useEffect(() => {
    if (isConnected && chainId && address) {
      getErc20Approve();
    }
  }, [isConnected, chainId]);

  // @ts-ignore
  const Web = <div id="PaybyCrypto" mode="web">

    <Modal
      width={"25vw"}
      visible={props.visible}
      closable={false}
      footer={null}
      bodyStyle={{  paddingBottom: '30' }}>
      <div className='flexsb pt-20 px-14' style={{ borderBottom: "1px solid #F3F3F3" }}>
        <div className='f-26 f-w-7'>Pay by Crypto</div>
        <CloseOutline onClick={() => props.onClose()} className='f-20' />
      </div>
      {
        loading ? <div className='flexcc w-100 h-80'><SpinLoading style={{ '--size': '48px' }} /></div> : <div className='px-14 py-44'>
          <div>
            <div className="f-16 f-w-5 mb-18">Pay by Crypto</div>

            <div className="w-100 h-r-36 br-12 flexsb px-12" style={{ background: '#F1F1F1' }}>
              <Select
                   defaultValue={"USDbC"}
                  size={"middle"}
                  onChange={(e)=>{
                    console.log(e);
                    setPayType(e);
                  }}
                  bordered={false}
                  style={{ width:"100%" }}
                  options={options}
                />
              {/* <div className='f-14 f-w-4'>USDT/USDC</div>
              <DownOutline /> */}
            </div>
          </div>

          <div className="my-18">
            <div className="flexsb mb-22">
              <div className="f-16 f-w-5">Blockchain</div>
              <div className="f-16 f-w-4" style={{ color: '#666' }}>BASE</div>
            </div>
            <div className="flexsb" style={{ color: '#666' }}>
                <div className="f-16 f-w-4" >Address</div>
              <CopyOutlined onClick={()=>copyCot(member.walletAddress)} />
            </div>
            <div className="br-12 my-10" style={{background:'#F1F1F1'}}>
              <TextArea disabled bordered={false} value={member.walletAddress} autoSize />
            </div>
              <div className="f-16 f-w-4 flexc">
                  <img  style={{width:"32px",height:"32px"}}   src={require('@/assets/web/nfts/zy.png')} alt="" />
                  <div  style={{color:"#9754F0",marginLeft:"8px"}} >
                     Please transfer  ${payData.orderAmount} to this address, and then click the payment button.
                  </div>
              </div>
          </div>

          <div >
            <div className="f-16 f-w-5">Details</div>
            <div className="flexsb my-22" style={{ color: '#666' }}>
              <div className="f-16 f-w-4" >NFT</div>
              <div className="f-16 f-w-4 flexc">{payData.commodityAmount} {payType}</div>
            </div>
            <div className="flexsb" style={{ color: '#666' }}>
              <div className="f-16 f-w-4" >gas fee</div>
              <div className="f-16 f-w-4 flexc">{payData.feeAmount} {payType}</div>
            </div>
          </div>
          <div className="w-100 h-r-2 mt-9 mb-18" style={{ background: "#E0E0E0" }}></div>
          <div className="flexsb">
            <div className="f-16 f-w-5">Subtotal</div>
            <div className="f-16 f-w-4" style={{ color: '#666' }}><span className="f-20 f-w-7 text-black">{payData.orderAmount}</span> {payType}</div>
          </div>
          {
            textTre(text) ?
              (payData.allowance >= payData.orderAmount ? <Button onClick={() => pay()}  loading={loading_but}  loadingText={'loading...'}  className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }}>
                {text}
              </Button> : <Button onClick={() => { approveErc20() }}  loading={loading_but}  loadingText={'loading...'}  className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }} >Approval</Button>)
              :
              <Button onClick={onClick}   loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }} >{text}</Button>
          }

            <Button onClick={()=>{props.onClose()}}   loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20 shou" >Cancel</Button>
        </div>
      }

    </Modal>


  </div>
  // @ts-ignore
  const Mobile = <div id="PaybyCrypto" mode="mobile">
    <Popup
      closeOnMaskClick={false}
      visible={props.visible}
      bodyStyle={{  borderRadius: '20px 20px 0 0', overflow: "auto" }}>
      <div className='flexsb pt-20 px-14' style={{ borderBottom: "1px solid #F3F3F3" }}>
        <div className='f-26 f-w-7'>Pay by Crypto</div>
        <CloseOutline onClick={() => props.onClose()} className='f-20' />
      </div>
      {
        loading ? <div className='flexcc w-100 h-80'><SpinLoading style={{ '--size': '48px' }} /></div> : <div className='px-14 py-44'>
          <div>
            <div className="f-16 f-w-5 mb-18">Pay by Crypto</div>
            <div className="w-100 h-r-36 br-12 flexsb px-12" style={{ background: '#F1F1F1' }}>
              <div className='f-14 f-w-4'>USDbC</div>
              <DownOutline />
            </div>
          </div>

          <div className="my-18">
            <div className="flexsb mb-22">
              <div className="f-16 f-w-5">Blockchain</div>
              <div className="f-16 f-w-4" style={{ color: '#666' }}>BASE</div>
            </div>
            <div className="flexsb" style={{ color: '#666' }}>
              <div className="f-16 f-w-4" >Address</div>
              <div className="f-16 f-w-4 flexc">
                <div className="w-r-128 dian">{member.walletAddress}</div>
                <RightOutline />
              </div>
            </div>

            <div className="f-16 f-w-4 flexc">
                  <img  style={{width:"32px",height:"32px"}}   src={require('@/assets/web/nfts/zy.png')} alt="" />
                  <div  style={{color:"#9754F0",marginLeft:"8px"}} >
                     Please transfer  ${payData.orderAmount} to this address, and then click the payment button.
                  </div>
              </div>
          </div>

          <div >
            <div className="f-16 f-w-5">Details</div>
            <div className="flexsb my-22" style={{ color: '#666' }}>
              <div className="f-16 f-w-4" >NFT</div>
              <div className="f-16 f-w-4 flexc">{payData.commodityAmount} USDbC</div>
            </div>
            <div className="flexsb" style={{ color: '#666' }}>
              <div className="f-16 f-w-4" >gas fee</div>
              <div className="f-16 f-w-4 flexc">{payData.feeAmount} USDbC</div>
            </div>
          </div>
          <div className="w-100 h-r-2 mt-9 mb-18" style={{ background: "#E0E0E0" }}></div>
          <div className="flexsb">
            <div className="f-16 f-w-5">Subtotal</div>
            <div className="f-16 f-w-4" style={{ color: '#666' }}><span className="f-20 f-w-7 text-black">{payData.orderAmount}</span> USDbC</div>
          </div>
          {
            textTre(text) ?
              (payData.allowance >= payData.orderAmount ? <Button onClick={() => pay()} loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }}>
                {text}
              </Button> : <Button onClick={() => { approveErc20() }}  loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }} >Approval</Button>)
              :
              <Button onClick={onClick}  loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20" style={{ border: "1px solid #343439" }} >{text}</Button>
          }

          <Button onClick={()=>{props.onClose()}}   loading={loading_but}  loadingText={'loading...'} className="w-100 h-r-52 br-12 flexcc f-16 f-w-7 mt-20 shou" >Cancel</Button>
        </div>
      }
    </Popup>
  </div>
  return Common.isMobile ? Mobile : Web
}