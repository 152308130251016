/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { To, useNavigate } from "react-router-dom";
import { SpinLoading } from "antd-mobile";
export function PlayOk() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  // @ts-ignore
  const Web = <div id="PlayOk" mode="web"></div>
  // @ts-ignore
  const Mobile = <div id="PlayOk" mode="mobile">
    <Navigation></Navigation>
    <div className="py-34 px-14 text-black">
      <div className="text-center f-20 f-w-7">Success!</div>
      <div className="text-center px-30 f-16 f-w-4" style={{color:'#242424'}}> On-chain transfer transaction record：xxxxxxxxx</div>
      <div className="flexcc mt-46"><img className="w-r-191 h-r-166" src={require('@/assets/mobile/playOK.png')} /></div>
      <div className="position-fixed b-50 w-r-292 h-r-42 br-12 flexcc f-16 f-w-7 text-white" style={{background:'#9754F0'}}>Check NFT</div>
    </div>
  </div>
  return Common.isMobile ? Mobile : Web
}