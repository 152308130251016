/* eslint-disable @typescript-eslint/no-unused-vars */
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { request } from "http";
import { httpGet, httpPost } from "@/utils/httpUtils";

export function Google() {



  const renderGoogle =() =>{
		httpPost('/oauth/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}
  // @ts-ignore
  const Web = <div id="Google" mode="web">
     <div onClick={renderGoogle} className="w-r-131 h-r-52 br-12 flexcc shou" style={{border: "1px solid #D9D9D9"}}>
        <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/login/Google.png')} /> 
        <div className="f-12 f-w-4 line-height-18">Google </div>
      </div>
  </div>
  // @ts-ignore
  const Mobile = <div id="Google" mode="mobile">
    <div onClick={renderGoogle} className="w-r-131 h-r-52 br-12 flexcc" style={{border: "1px solid #D9D9D9"}}>
            <img className="w-r-20 h-r-20 mr-18" src={require('@/assets/mobile/login/Google.png')} /> 
            <div className="f-12 f-w-4 line-height-18">Google </div>
          </div>
  </div>
  return Common.isMobile ? Mobile : Web
}