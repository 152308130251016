/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./NftHome.scss";
import { Common } from "@/app/Common";
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Footer } from '@/ui/components/Footer'
import { Space, Swiper, Collapse,Image } from 'antd-mobile'
import { To, useNavigate } from "react-router-dom";
import { httpGet, httpPost } from '@/utils/httpUtils'
import type { CollapseProps } from 'antd';
import { Collapse as PCCollapse } from 'antd';
import { Qa } from '@/ui/components/Qa'

import {
  RightOutlined
} from '@ant-design/icons';
export function NftHome() {
  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }

  const [nftData, setNftData] = useState<any>({})
  const [nftList, setNftList] = useState<any>([])
  useEffect(() => {
    detectMintNft()
    getHomeNFT()
  }, [])

  const getHomeNFT = () => {
    httpPost("/nftDetail/getPage",{page:1,limit:20}).then((res:any) => {
      const {data}=res
      console.log(data);
      if(data.code === 0) {
        setNftList(data.data.list)
      }
    })
  }

  const  detectMintNft = () => {
    httpGet("/memberrecharge/detectMintNft").then((res:any) => {
      const {data}=res
      if(data.code === 0) {
        setNftData(data.data)
      }
    })
  }

  const { Panel } = PCCollapse;
  const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']
  // @ts-ignore
  const Web = <div id="NftHome" mode="web">
     <Navigation></Navigation>
      <div className="NftHomeBox text-white">
          <div  style={{paddingTop:"8.3333vw",paddingLeft:"22.1354vw"}} >
            <img className="hn"  src={require('@/assets/web/HftHome/hN.png')} />
            <div className="f-16 f-w-4 w-r-425 mt-24 mb-38">Own an AI companion and support the Christian digital world in DApp 'Lumi'.</div>
            <div onClick={() => skip('/nfts')}  className="w-r-140 h-r-52 br-12 flexcc bg-xitong f-16 f-w-7 mb-32 shou">Get NFT</div>
            <div className="flexc">
              <div>
                <div className="f-26 f-w-7">500+</div>
                <div className="f-16 f-w-5">Artwork</div>
              </div>
              <div className="mx-32">
                <div className="f-26 f-w-7">60k+</div>
                <div className="f-16 f-w-5">Auctions</div>
              </div>
              <div>
                <div className="f-26 f-w-7">20+</div>
                <div className="f-16 f-w-5">Mystery Blind Box</div>
              </div>
            </div>
          </div>
      </div>
      {/* NFTS */}
      <div className="bg-white w-100 py-60 text-black">
        <div className="flexcc f-32 f-w-9 intelfont">500 NFTs</div>
        <div className="my-45 px-46 flexsb">
          <div></div>
          <div onClick={() => skip('/nfts')} className="flexcc shou">View All<RightOutlined className="ml-10"  /></div>
        </div>
        <div className="w-100 h-r-280 d-flex" style={{ overflowX: 'auto' }}>
          {nftList.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  index === nftList.length - 1 ? 'br-12 ' : 'mr-20 br-12 shou'
                }>
                <div className="position-relative w-r-285 h-r-280 ">
                    <img className="position-absolute w-r-285 h-r-280 br-12" src={item.imgUrl} style={{zIndex:21}} />
                    <div className="pb-24 position-absolute w-r-285 h-r-280 br-12 text-white f-20 f-w-7 text-center d-flex justify-content-center  align-items-end"   style={{
                      zIndex: 22,
                      background: "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(255, 255, 255, 0.00) 50%)"
                    }}>
                      { JSON.parse(item.metaData).name}
                    </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* Blindbox */}
      <div className='flexcc w-100' style={{background:'#22212F'}} >
        <div className="w-r-1280" >
      
      <div className="w-100 px-228 py-60 text-white" >
        <div className="text-center f-32 f-w-9">Blindbox</div>
        <div className="f-16 f-w-4 my-32">Let's see what God has prepared for you. It could be an unlimited conversation pass, a free invitaion to friends, your exclusive AI companion. Everytime you open it, you will find the rich and abundant gift from God!</div>
        <div className="d-flex flex-column align-items-center">
          <div className='w-r-276 h-r-309 br-12 p-14  position-relative' style={{backgroundColor:'#2C2A3F'}}>
            <img className="w-100 h-r-212 " src={require('@/assets/web/HftHome/box.png')} />
            <div className='flexsb mt-9'>
              <div className='text-white f-14 f-w-4'>Price</div>
              <div className='f-20 f-w-7'>${nftData?.nftConfig?.amount}</div>
            </div>
            <div className='w-r-265 h-r-8 position-absolute' style={{bottom:'-0.6vw',left:' 0.3906vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
            <div className='w-r-252 h-r-14 position-absolute' style={{bottom:'-1.0938vw',left:'0.7813vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
          </div>
          <div className="w-r-239 h-r-52 flexcc br-12 bg-xitong f-16 f-w-7 mt-39 shou" onClick={() => skip('/BlindBox')}>Open it!</div>
        </div>
      </div>
      </div>
      </div>
      {/* Pay to Support  */}
      <div className='flexcc w-100' >
        <div className="w-r-1280" >
      <div className="px-231 py-60 bg-white text-black">
        <div className="flexcc">
          <img className="w-r-259 h-r-259 br-12 mr-110" src={require('@/assets/mobile/nfts/nftbay.png')} />
          <div className="w-r-258">
            <div className="f-26 f-w-7">Pay to Support </div>
            <div className="f-16 f-w-4 my-16  support">To get more conversation times and at the same time-- support this tech-driven ministry of spreading the Gospel.</div>
            <div className="w-r-215 h-r-52 br-12 flexcc bg-xitong f-16 f-w-7 text-white shou" onClick={() => skip('/PayToSupport')}>I want it!</div>
          </div>
        </div>
        {/* Q&A */}
        <Qa></Qa>
        {/* <div>
          <div className="flexsb mt-69">
              <div className="f-26 f-w-7 text-black">Q&A</div>
              <div className="f-14 f-w-4" style={{color:'#666666'}}>{"more >"}</div>
          </div>
          <PCCollapse defaultActiveKey={['2']} ghost={true} expandIconPosition="end">
            <Panel header="Here is a title here is a title here is a title here is a title here is a title" key="1">
              <p>1212</p>
            </Panel>
            <Panel header="Here is a title here is a title here is a title here is a title here is a title" key="2">
              <div className="flexsb f-14 f-w-4">
                  <div className="dian w-75">You can purchase conversation...</div>
                  <div className="w-25 text-right" style={{color:'#AC76F3'}}>See more</div>
              </div>
            </Panel>
          </PCCollapse>
        </div> */}
      </div>
      </div>
      </div>

      <Footer></Footer>
  </div>
  // @ts-ignore
  const Mobile = <div id="NftHome" mode="mobile">
    <Navigation></Navigation>
    <div className="NftHomeBox">
        <img className="w-100 h-r-359"  onClick={() => skip('/BlindBox')} src={require('@/assets/mobile/nfts/nftHome.png')} />
        <div className="nfts">
          <div className="flexcc my-39 f-32 f-w-9">500 NFTs</div>
          <div className="flexsb px-8">
            <div className="py-11 f-16 f-w-7 w-r-106 flexcc" style={{borderBottom:"2px solid #fff"}}>All</div>
            <div className="flexc" onClick={() => skip('/nfts')}>
              <div className="f-16 f-w-5 mr-10" style={{color:'#AEAEAE'}}>More</div>
              <img className="w-r-10 h-r-15 " src={require('@/assets/mobile/my/right.png')} /> 
            </div>
          </div>
          <div className="py-22">
            <Swiper slideSize={90} trackOffset={5} stuckAtBoundary={false}>
              {
                nftList.map((item:any,index:number) => {
                  return <Swiper.Item  key={index}>
                      <div className="w-r-276 h-r-309 br-12 p-13"  style={{ background: "#48484D" }}>
                        <Image  className="w-r-248 h-r-212 " lazy src={item.imgUrl}/>
                        <div className="f-14 f-w-4 mt-31 text-center">
                          { JSON.parse(item.metaData).name}
                        </div>
                      </div>
                  </Swiper.Item>
                })

              }
                
                {/* <Swiper.Item >
                    <div className="w-r-276 h-r-309 br-12 p-13"  style={{ background: "#48484D" }}>
                       <img className="w-r-248 h-r-212 " src={require('@/assets/mobile/nfts/nfty.png')} /> 
                    </div>
                </Swiper.Item>
                <Swiper.Item >
                    <div className="w-r-276 h-r-309 br-12 p-13"  style={{ background: "#48484D" }}>
                       <img className="w-r-248 h-r-212 " src={require('@/assets/mobile/nfts/nfty.png')} /> 
                    </div>
                </Swiper.Item> */}
               
            </Swiper>
          </div>
        </div>


        {/* NFTBlindbox */}
        <div className="px-22 pb-62">
          <div className="flexcc f-32 f-w-9">Blindbox</div>
          <div className="text-center f-16 f-w-4">
          Let's see what God has prepared for you. It could be an unlimited conversation pass, a free invitaion to friends, your exclusive AI pastor. Everytime you open it, you will find the rich and abundant gift from God!
          </div>
          <div className='w-100 h-r-279 br-12 p-14 mb-30 mt-37  position-relative' style={{backgroundColor:'#48484D'}}>
            <img className="w-100 h-r-212 " src={require('@/assets/mobile/nfts/mh.png')} />
            <div className='flexsb mt-9'>
              <div className='text-white f-14 f-w-4'>price</div>
              <div className='f-20 f-w-7'>${nftData?.nftConfig?.amount}</div>
            </div>
            <div className='w-r-265 h-r-8 position-absolute' style={{bottom:'-2.4vw',left:'1.5725vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
            <div className='w-r-252 h-r-14 position-absolute' style={{bottom:'-4.375vw',left:'3.125vw', borderRadius:'0 0 12px 12px',background:'rgba(137, 76, 218, 0.2)'}}></div>
          </div>
          {/* <img className="w-100 h-r-324 mb-30 mt-17" src={require('@/assets/mobile/nfts/nftm.png')} /> */}
          <div className="w-100 h-r-51 flexcc br-12 bg-xitong f-16 f-w-7 shou" onClick={() => skip('/BlindBox')}>Open it!</div>
        </div>

        <div className="pay px-14 pb-23 pt-50 bg-white">
          <img className="w-100 h-r-280 br-12" src={require('@/assets/mobile/nfts/nftbay.png')} />
          <div className="f-26 f-w-7 my-16" style={{color:'#343439'}}>Pay to Support </div>
          <div className="f-16 f-w-4" style={{color:'#343439'}}>To get more conversation times and at the same time-- support this tech-driven ministry of spreading the Gospel.</div>
          <div className="w-50 h-r-51 flexcc br-12 bg-xitong f-16 f-w-7 mt-48 shou" onClick={() => skip('/PayToSupport')}>I want it!</div>
        </div>

       
        <div className="p-15 bg-white">
               <Qa></Qa>
        </div>
         
    </div>
    <Footer></Footer>
  </div>
  return Common.isMobile ? Mobile : Web
}