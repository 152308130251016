/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { Input, Toast, Modal,DotLoading } from 'antd-mobile'
//  export const web_url="http://daitaowxsmall.mynatapp.cc"
//  export const host_url="http://daitaowxsmall.mynatapp.cc"
// export const web_url="http://ganggang.nat300.top"
// export const host_url="http://ganggang.nat300.top"
export const web_url="https://godwithme.ai"
export const host_url="https://godwithme.ai"

// 添加响应拦截器
axios.interceptors.response.use(function (response) {

// 对响应数据做点什么
  return response;

},function (error) {

// 对响应错误做点什么

return Promise.reject(error);

});

// get请求

export function httpGet(api:any){
  const instance = axios.create({
    timeout:50000,
     headers:{
             'Content-Type': 'application/json',
              'token': localStorage.getItem("token") as string
             },  // 请求头 token 可以放里面
   })

  return new Promise((reslove,reject)=>{
    instance.get(host_url+"/api/app"+api).then(res=>{
      if(res.data.code === 401){
        Toast.show({
          icon: 'fail',
          content: 'Please login', //信息
        })
        setTimeout(()=>{
          window.location.hash="/login"
        },1000)
      }else {
        reslove(res)
      }
    }).catch((err)=>{
      reject(err)
    })
  })
};



export function httpGetA(api:any){
  const instance = axios.create({
    timeout:50000,
     headers:{
             'Content-Type': 'application/json',
      },  // 请求头 token 可以放里面
   })


  return new Promise((reslove,reject)=>{
    instance.get(api).then(res=>{
      if(res.data.code === 401){
        Toast.show({
          icon: 'fail',
          content: 'Please login', //信息
        })
        setTimeout(()=>{
          window.location.href="/login"
        },1000)
      }else {
        reslove(res)
      }
    }).catch((err)=>{
      reject(err)
    })
  })
};
// post请求

export function httpPost(api:any,data:any){
  return new Promise((reslove,reject)=>{
    const instance = axios.create({
      timeout:50000,
      headers:{
              'Content-Type': 'application/json',
                'token': localStorage.getItem("token")+""
              },  // 请求头 token 可以放里面
    })
    instance.post(host_url+"/api/app"+api,data).then(res=>{
      if(res.data.code === 401){
        Toast.show({
          icon: 'fail',
          content: 'Please login', //信息
        })
        setTimeout(()=>{
          window.location.hash="/login"
        },1000)
      }else {
        reslove(res)
      }

    }).catch((err)=>{

      reject(err)

        })

    })

};

