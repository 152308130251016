/* eslint-disable @typescript-eslint/no-unused-vars */
import './PurchaseRecord.scss'
import { Common } from '@/app/Common'
import { useEffect, useState } from 'react'
import { Navigation } from '@/ui/components/Navigation'
import { Back } from '@/ui/components/Back'
import { Tabs,InfiniteScroll,DotLoading } from 'antd-mobile'
import { httpGet, httpPost } from '@/utils/httpUtils'
import { To, useNavigate } from 'react-router-dom'
export function PurchaseRecord() {

  enum status {
    待充值 ,
    已充值,
    金额异常,
    订单异常
  }

  enum productType {
    "Purchase more times",
    "Get NFT",
  }

  const navigate = useNavigate();
  const skip = (url: To) => {
    navigate(url)
  }
  const [activeKey, setActiveKey] = useState('1')
  const [purchaseRecordList, setPurchaseRecordList] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(true)

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>--- Loading completed ---</span>
        )}
      </>
    )
  }

  const [page, setPage] = useState(1)
  const loadMore = async () => {
    await setPage(page + 1)
    console.log(page);
    await rechargeOrderPage()
  }
 

  const rechargeOrderPage = async () => {
    await httpPost("/memberrecharge/rechargeOrderPage",{page:page,limit:10}).then((res:any) => {
      const {data}=res
      if(data.code === 0) {
        setPurchaseRecordList(val => [...val, ...data.data.list])
        data.data.list.totalCount === purchaseRecordList.length ? setHasMore(true):setHasMore(false) 
        console.log(data.data.list.totalCount === purchaseRecordList.length );
      }
    })
  }


  const [purchaseRecordListPC, setPurchaseRecordListPC] = useState<any[]>([])

  useEffect(() => {
    rechargeOrderPagePC()
  }, [])
  const rechargeOrderPagePC = async () => {
    await httpPost("/memberrecharge/rechargeOrderPage",{page:1,limit:10}).then((res:any) => {
      const {data}=res
      if(data.code === 0) {
        setPurchaseRecordListPC([...data.data.list])
      }
    })
  }

  // @ts-ignore
  const Web = <div id="PurchaseRecord" mode="web">
      <Navigation></Navigation>
      <Back></Back>
      <div className='flexcc w-100 pt-140' >
      <div className="w-r-1280" >
       <div className='px-424'>
        <div className='f-32 f-w-9 mb-60'>Purchase record</div>
        {/* {
          purchaseRecordListPC.map((item,index) => {
            return <div className='w-100 py-20' style={{borderBottom:'1px solid #E0E0E0'}} key={index}>
                <div className='flexsb'>
                  <div className='f-16 f-w-7 w-60 dian shou' style={{color:'#242424'}}>{productType[item.productType]}</div>
                  <div className='f-14 f-w-4' style={{color:'#60A774'}}>{status[item.status]}</div>
                </div>
                <div className='flexsb my-8'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>Amount</div>
                  <div className='f-20 f-w-7' style={{color:'#343439'}}>${item.amount}</div>
                </div>
                <div className='flexsb'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>Time</div>
                  <div className='f-14 f-w-4' style={{color:'#343439'}}>{item.tradeDate}</div>
                </div>
            </div>
          })
        } */}

          {
            purchaseRecordListPC.length > 0 ? 
            <div>
                {
                  purchaseRecordListPC.map((item,index) => {
                    return <div className='w-100 py-20' style={{borderBottom:'1px solid #E0E0E0'}} key={index}>
                        <div className='flexsb'>
                          <div className='f-16 f-w-7 w-60 dian shou' style={{color:'#fff'}}>{productType[item.productType]}</div>
                          <div className='f-14 f-w-4' style={{color:'#60A774'}}>{status[item.status]}</div>
                        </div>
                        <div className='flexsb my-8'>
                          <div className='f-12 f-w-4' style={{color:'#fff'}}>Amount</div>
                          <div className='f-20 f-w-7' style={{color:'#fff'}}>${item.amount}</div>
                        </div>
                        <div className='flexsb'>
                          <div className='f-12 f-w-4' style={{color:'#fff'}}>Time</div>
                          <div className='f-14 f-w-4' style={{color:'#fff'}}>{item.tradeDate}</div>
                        </div>
                    </div>
                  })
                }
            </div>
            :<div className='text-center'>
              <div className='f-14'>Here is no data</div>
              <img
                  className="w-r-180 h-r-172"
                  src={require('@/assets/web/noData.png')}
                />
            </div>
          }
       
      </div>
      </div>
    </div>
  </div>
  // @ts-ignore
  const Mobile = (<div id="PurchaseRecord" mode="mobile">
      <Navigation></Navigation>
      <div className="purchaseRecordBox px-14 py-34">
        <div className="f-26 f-w-7 mb-30">Purchase record</div>
        {
          purchaseRecordList.map((item,index) => {
            return <div className='w-100 py-20' style={{borderBottom:'1px solid #E0E0E0'}} key={index}>
                <div className='flexsb'>
                  <div className='f-16 f-w-7 w-60 dian' style={{color:'#242424'}}>{productType[item.productType]}</div>
                  <div className='f-14 f-w-4' style={{color:'#60A774'}}>{status[item.status]}</div>
                </div>
                <div className='flexsb my-8'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>Amount</div>
                  <div className='f-20 f-w-7' style={{color:'#343439'}}>${item.amount}</div>
                </div>
                <div className='flexsb'>
                  <div className='f-12 f-w-4' style={{color:'#666666'}}>Time</div>
                  <div className='f-14 f-w-4' style={{color:'#343439'}}>{item.tradeDate}</div>
                </div>
            </div>
          })
        }
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} >
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
        
      </div>
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
